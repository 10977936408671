import React, {useState} from 'react';
import './Login.css'; // Create appropriate CSS for your components
import LoginForm from './LoginForm';
import Logo from '../../imining-logos/WEB_iMining_logo blue large.png'
import {Amplify} from 'aws-amplify';
import config from '../../amplifyconfiguration.json';
import Background from "../../imining-logos/imining-background.svg";
import coverImage from '../../imining-logos/dalle-cover.png';
Amplify.configure(config);

function formatErrorMessage(errorMessage) {
    // Split the error message into words
    const words = errorMessage.split(/(?=[A-Z])/);

    const formattedWords = words.map((word, index) => {
        if (index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return word.toLowerCase();
    });
    return formattedWords.join(' ');
}

const LoginPage = () => {
    const [errorMessage, setErrorMessage] = useState(null)
    return (
        <div className="login-page">
            <div className="login-left-side">
                <img className="image-placeholder" src={coverImage} alt={''}/>
            </div>
            <div className="login-right-side">
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img className='logo' src={Logo}/>
                    <div className='logo-text'>BLASTTRACK</div>
                    <div className='logo-text'>Blast Smarter</div>
                </div>
                {errorMessage && <Error message={formatErrorMessage(errorMessage)}/>}
                <LoginCard>
                    <LoginForm setErrorMessage={setErrorMessage}/>
                </LoginCard>

                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    justifySelf: 'flex-start',
                    backgroundImage: `url(${Background})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    zIndex: -10,
                    rotate: '225deg',
                }}/>


            </div>
        </div>
    );
};


const LoginCard = ({children}) => {
    return <div className="login-card">{children}</div>;
};

const Error = ({message}) => {
    return (
        <div className="error-message">
            {message}
        </div>
    );
}

export default LoginPage;
