import React from 'react';
import './CompanyRedirect.css';
import {useLocation} from "react-router-dom";

const CompanyRedirect = ({companies}) => {
    const location = useLocation();
    const {token} = location.state;
    // Placeholder onClick function
    const handleClick = (company, token) => {
        window.location.href = `https://${company}.secured-imining.tech?token=${token}`;
    };

    return (
        <div className="button-container">
            <div className="heading">Where we goin?</div>
            <div className="button-row">
                {companies.map((name, index) => (
                    <button
                        key={index}
                        onClick={() => handleClick(name, token)}
                        className="button"
                    >
                        {name.toUpperCase()}
                    </button>
                ))}
            </div>
            <div style={{color: 'white', position: 'absolute', bottom: '5%'}}>This screen is only visible to iMining employees</div>
        </div>
    );
};

export default CompanyRedirect;
