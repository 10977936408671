import React, {useState} from 'react';
import {Amplify} from 'aws-amplify';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';
import {CircularProgress} from "@mui/material";
import config from '../../amplifyconfiguration.json';
Amplify.configure(config);

function SignUpForm({callback, roleOptions, userToUpdate}) {
    const [email, setEmail] = useState(userToUpdate?.userID ?? '');
    const [given_name, setGivenName] = useState(userToUpdate?.given_name ?? '');
    const [family_name, setFamilyName] = useState(userToUpdate?.family_name ?? '');
    const [role, setRole] = useState(userToUpdate?.role ?? '');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const handlePermissionsChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
        setPermissions(selectedOptions);
    };


    const API_ENDPOINT = 'https://ssknzqim70.execute-api.eu-west-1.amazonaws.com/test/users'; // Replace with your API Gateway URL

    async function handleSignUp(e) {
        e.preventDefault();
        setLoading(true);
        const userData = {
            email: email,
            givenName: given_name,
            familyName: family_name,
            role_id: role,
            operation: userToUpdate ? 'update' : 'create'
        };

        try {
            const response = await fetch(API_ENDPOINT, {
                method: 'POST',
                body: JSON.stringify(userData),
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                console.log(response)
                throw new Error(`Error creating user: ${response.status}`);
            }

            const data = await response.json();
            setResponse(`User ${userToUpdate ? "updated" : "created"} successfully`);
            setShowSuccess(true);
            setLoading(false);

        } catch (error) {
            setResponse(error.message);
            setShowError(true);
            console.error('Error signing up:', error);
            setLoading(false);

        }
    }

    return (
        // In your component file
        <div>
            {loading ? <CircularProgress/> :
                (response &&
                    < Alert icon={showSuccess ? <CheckIcon fontSize="inherit"/> : <CrossIcon fontSize="inherit"/>}
                            severity={showSuccess ? 'success' : (showError ? 'error' : 'info')}>
                        {response}
                    </Alert>)}

            <form className="signup-form" onSubmit={(e) => {
                handleSignUp(e).then(() => {
                    callback(showSuccess ? 'success' : 'error');
                })
            }}>
                <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                            if (userToUpdate?.userID.length > 0) {
                                return;
                            }
                            setEmail(e.target.value);
                        }} className="input-field"
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="role">Role</label>
                    <select
                        id="role"
                        value={role}
                        onChange={e => setRole(e.target.value)}
                        className="input-field"
                    >
                        <option value="">Select a role</option>
                        {roleOptions.map((option) => {
                            return <option value={option.role_id}>{option.role_name}</option>
                        })}

                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="given-name">Given Name</label>
                    <input
                        type="text"
                        id="given-name"
                        value={given_name}
                        onChange={(e) => setGivenName(e.target.value)}
                        className="input-field"
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="family-name">Family Name</label>
                    <input
                        type="text"
                        id="family-name"
                        value={family_name}
                        onChange={(e) => setFamilyName(e.target.value)}
                        className="input-field"
                    />
                </div>

                <button type="submit" className="submit-btn"> {userToUpdate ? 'Edit User' : 'Add User'}</button>
            </form>
        </div>

    )
        ;
}

export default SignUpForm;
