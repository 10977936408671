import {confirmResetPassword, confirmSignIn, fetchAuthSession, resetPassword, signIn, signOut} from 'aws-amplify/auth';
import './Login.css';
import React, {useEffect, useState} from 'react';
import {useAuth} from "../../AuthContext";
import {jwtDecode} from "jwt-decode";
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import PinIcon from '@mui/icons-material/Pin';
import LockIcon from '@mui/icons-material/Lock';
import {Amplify} from 'aws-amplify';
import config from '../../amplifyconfiguration.json';
import {useNavigate} from 'react-router-dom'; // Import useNavigate hook
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CircularProgress} from "@mui/material";


Amplify.configure(config);

async function handleConfirmResetPassword({
                                              username, confirmationCode, newPassword, setErrorMessage
                                          }) {
    try {
        await confirmResetPassword({username, confirmationCode, newPassword});
        return true;
    } catch (error) {
        setErrorMessage(error.message);
        console.log(error);
        return false;
    }
}

async function handlePasswordChange(username, newPassword, setIsLoading, setErrorMessage, isForgotten) {
    const confirmSignInInput = {
        challengeResponse: newPassword,
    };
    setIsLoading(true);
    try {
        const out = await confirmSignIn(confirmSignInInput);
        if (out.isSignedIn) {
            return true;
        }
        setIsLoading(false);
    } catch (error) {
        setErrorMessage(error.message);
        console.error('Error confirming sign-in:', error);
        return error;
    }
}

const getPermissions = async (authToken, role_id) => {
    try {
        const params = new URLSearchParams({role_id: role_id});
        let headers = {'Authorization': authToken}
        let response = await fetch(`https://ssknzqim70.execute-api.eu-west-1.amazonaws.com/test/permissions?${params}`, {
            method: 'GET', headers: headers
        })
        const content = await response.text();
        const data = JSON.parse(content);
        const permissions = data['permissions']
        const locations = data['locations']
        const role_name = data['role_name']
        return {permissions, locations, role_name, success: true};
    } catch (error) {
        console.error('Error retrieving permissions:', error);
        return {success: false, error: error.message};
    }
}

async function getCurrentSessionInfo() {
    try {
        const {accessToken, idToken} = (await fetchAuthSession({forceRefresh: true})).tokens ?? {};
        if (!accessToken || !idToken) {
            return {success: false, error: 'No tokens found'};
        }

        const decodedToken = jwtDecode(idToken.toString());

        let {permissions, role_name, locations} = await getPermissions(idToken, decodedToken['custom:role']);

        const user = {
            email: decodedToken['email'],
            givenName: decodedToken['given_name'],
            familyName: decodedToken['family_name'],
            role: decodedToken['custom:role'],
            company: decodedToken['custom:company'],
            locations: locations,
            role_name: role_name,
            permissions: permissions
        };

        return {accessToken, idToken, user, success: true};
    } catch (err) {
        return {success: false, error: err.message};
    }
}

const LoginForm = ({setErrorMessage}) => {

    const [userMustLogin, setUserMustLogin] = useState(true);
    const [userMustChangePassword, setUserMustChangePassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {setToken, setUser, userInfo, authToken} = useAuth();
    const navigate = useNavigate(); // Hook to get the navigate function


    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const sessionInfo = await getCurrentSessionInfo();
            if (sessionInfo.success) {
                setToken({accessToken: sessionInfo.accessToken, idToken: sessionInfo.idToken});
                setUser(sessionInfo.user);
                setUserMustLogin(false);
                setIsLoading(false);

            } else {
                setIsLoading(false);
            }
        })()
    }, []);

    useEffect(() => {
        if (userInfo && userInfo['email'] && (userMustChangePassword || forgotPassword)) {
            const company = userInfo.company;
            if (userInfo.company === 'imining') {
                navigate('/company-redirect', {
                    state: {
                        token: authToken['idToken'].toString(),
                    }
                })
            } else {
                window.location.href = `https://${company}.secured-imining.tech?token=${authToken['idToken']}`;
            }
        }
        if (!userInfo) {
            setUserMustLogin(true)
        }


    }, [userInfo, userMustChangePassword]);

    const handleSubmit = async (event, setIsLoading) => {
        event.preventDefault();
        setErrorMessage(null);
        try {
            if (userMustLogin) {
                setIsLoading(true);
                const {isSignedIn, nextStep} = await signIn({username, password});
                if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                    setUserMustChangePassword(true);
                }
                if (isSignedIn) {
                    const sessionInfo = await getCurrentSessionInfo();
                    setIsLoading(false);
                    if (sessionInfo.success) {
                        setToken({accessToken: sessionInfo.accessToken, idToken: sessionInfo.idToken});
                        setUser(sessionInfo.user);
                        setUserMustLogin(false);
                        const company = sessionInfo.user.company;
                        if (company === 'imining') {
                            navigate(`/company-redirect`, {
                                state: {
                                    token: sessionInfo.idToken.toString(),
                                }
                            })
                        } else {
                            window.location.href = `https://${company}.secured-imining.tech?token=${sessionInfo.idToken}`;
                        }
                    } else {
                        setErrorMessage(sessionInfo.error);
                    }
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
            setErrorMessage(error.message)
        }
    };


    const renderForm = () => {
        if (isLoading) {
            return (<div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress/>
            </div>)
        }
        if (forgotPassword) {
            return <ForgotPassword setErrorMessage={setErrorMessage} setIsLoading={setIsLoading}
                                   setForgotPassword={setForgotPassword}
            />
        } else if (userMustLogin) {
            if (userMustChangePassword) {
                return <SetNewPassword setErrorMessage={setErrorMessage} setIsLoading={setIsLoading}
                                       username={username}/>;
            } else {
                return (<form className="login-form" onSubmit={(event) => handleSubmit(event, setIsLoading)}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div className="input-icon-container">
                            <PersonIcon className="icon" fill-/>
                            <input
                                type="text"
                                placeholder="Username"
                                onChange={(e) => setUsername(e.target.value)}
                                className="input-with-icon"
                                style={{backgroundColor: 'red'}}
                            />
                        </div>
                        <div className="input-icon-container">
                            <LockIcon className="icon"/>
                            <input
                                type="password"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                className="input-with-icon"
                            />
                        </div>
                    </div>
                    <button type="submit">Sign in</button>
                    <a href="#" onClick={() => setForgotPassword(true)} style={{color: '#f87f00'}}>Forgot your
                        password?</a>
                </form>);
            }
        } else {
            return <ContinueAsUser user={userInfo}/>;
        }

    };

    return (<div className={isLoading ? 'non-clickable' : ''}>

        {renderForm()}
    </div>);
};


const passwordPolicy = {
    minLength: 8,
    requireUppercase: true,
    requireLowercase: true,
    requireNumbers: true,
    requireSpecialCharacter: true,
    passwordsMatch: true,
};

const validatePassword = (password, confirmPassword) => ({
    minLength: password.length >= passwordPolicy.minLength,
    requireUppercase: /[A-Z]/.test(password),
    requireLowercase: /[a-z]/.test(password),
    requireNumbers: /\d/.test(password),
    requireSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    passwordsMatch: password === confirmPassword,
});

//Badingus1!
const SetNewPassword = ({username, setIsLoading, setErrorMessage, isForgotten, confirmationCode}) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordValidation, setPasswordValidation] = useState({});
    const {setUser, setToken} = useAuth();


    useEffect(() => {
        setPasswordValidation(validatePassword(newPassword, confirmPassword));
    }, [newPassword, confirmPassword]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(null);
        if (newPassword === confirmPassword) {
            try {
                let passwordWasChanged
                if (isForgotten) {
                    setIsLoading(true);
                    passwordWasChanged = await handleConfirmResetPassword({
                        username, confirmationCode: confirmationCode, newPassword: confirmPassword, setErrorMessage
                    });

                    if (passwordWasChanged) {
                        await signIn({username, password: confirmPassword});
                    }

                } else {
                    passwordWasChanged = await handlePasswordChange(username, newPassword, setIsLoading, setErrorMessage);
                }
                if (passwordWasChanged) {
                    const sessionInfo = await getCurrentSessionInfo();
                    if (sessionInfo.success) {
                        setToken({accessToken: sessionInfo.accessToken, idToken: sessionInfo.idToken})
                        setUser(sessionInfo.user);
                    }
                }
            } catch (error) {
                console.log(error);
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        } else {
            setErrorMessage('Passwords do not match');
        }
    };


    return (<form style={{position: 'relative'}} className="login-form" onSubmit={handleSubmit}>
        <div style={{fontWeight: 'bold', alignSelf: 'center', color: '#002842', paddingBottom: 10}}>Enter your new
            password
        </div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <div className="input-icon-container" style={{marginBottom: !isForgotten ? 0 : 0}}>
                <LockIcon className="icon"/>
                <input
                    type="password"
                    placeholder="New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="input-with-icon"
                />
            </div>
            <div className="input-icon-container">
                <LockIcon className="icon"/>
                <input
                    type="password"
                    placeholder="Confirm New Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="input-with-icon"
                />
            </div>
        </div>
        <button type="submit"
                className={Object.values(passwordValidation || {}).every(v => v) ? '' : 'non-clickable'}>Set
            Password
        </button>

        <ul style={{marginTop: '10px', position: 'absolute', bottom: 'calc(-105% - 40px - 30px)', left: '25%'}}>
            {Object.keys(passwordPolicy).map((rule) => (
                <li key={rule} style={{color: passwordValidation[rule] ? 'green' : 'red'}}>
                    {rule === 'minLength' && `Minimum length of ${passwordPolicy[rule]}`}
                    {rule === 'requireUppercase' && 'Requires uppercase letter'}
                    {rule === 'requireLowercase' && 'Requires lowercase letter'}
                    {rule === 'requireNumbers' && 'Requires a number'}
                    {rule === 'requireSpecialCharacter' && 'Requires a special character'}
                    {rule === 'passwordsMatch' && 'Passwords must match'}
                </li>))}
        </ul>
    </form>);
};

const ContinueAsUser = ({user}) => {
    const {setToken, setUser, authToken} = useAuth();
    const navigate = useNavigate(); // Hook to get the navigate function


    const handleSignOut = async () => {
        await signOut();
        setToken(null);
        setUser(null);
    }
    return (<div className="login-form">
        <button onClick={() => {
            const company = user.company;
            if (company === 'imining') {
                navigate('/company-redirect', {
                    state: {
                        token: authToken['idToken'].toString(),
                    }
                })
            } else {
                window.location.href = `https://${company}.secured-imining.tech?token=${authToken['idToken']}`;
            }
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontWeight: 'bold',
                justifyContent: 'center'
            }}>
                <LoginIcon/>
                <div style={{marginLeft: 10}}>Continue as {user?.email ?? ''}</div>
            </div>

        </button>
        <button className='different-user-button' onClick={handleSignOut}>Sign in as a different user?
        </button>

    </div>);
}


const ForgotPassword = ({setErrorMessage, setIsLoading, setForgotPassword}) => {
    const [email, setEmail] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');


    async function handleResetPassword(username) {
        try {
            const {nextStep} = await resetPassword({username});
            if (nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
                setCodeSent(true);
            }
        } catch (error) {
            setErrorMessage(error.message);
            setIsLoading(false);
            console.log(error);
        }
    }

    const askForEmail = () => {
        return (<div className="login-form">
            <div style={{position: 'absolute', top: -45, left: -35}}>
                <ArrowBackIcon className='icon' onClick={() => setForgotPassword(false)}
                               style={{color: '#002842', cursor: 'pointer'}}/>
            </div>
            <div style={{fontWeight: 'bold', alignSelf: 'center', color: '#002842'}}>Enter your email below and we
                will
                send you an email to reset your password
            </div>
            <input
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className="input-with-icon"
                style={{
                    border: '1px solid #ccc', borderRadius: 5, marginTop: 10, width: '70%', alignSelf: 'center',
                }}
            />
            <button onClick={() => handleResetPassword(email)} style={{
                marginTop: 10,
                width: 'calc(70% + 30px)',
                alignSelf: 'center',
                backgroundColor: '#f87f00',
                color: 'white',
                borderRadius: 5,
                padding: 10
            }}>Reset Password
            </button>

        </div>)
    }

    const resetWithCode = () => {
        return (<div className="login-form">
            <div style={{position: 'absolute', top: 10, left: 10}}>
                <ArrowBackIcon className='icon' onClick={() => setForgotPassword(false)} style={{color: '#002842'}}/>
            </div>
            <div style={{fontWeight: 'bold', alignSelf: 'center', color: '#002842', paddingBottom: 10}}>Enter the
                code sent to your
                email
            </div>
            <div className='input-icon-container' style={{marginBottom: 10, marginTop: 10, alignSelf: 'center'}}>
                <PinIcon className="icon"/>
                <input
                    type="text"
                    placeholder="Code"
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    className="input-with-icon"
                    style={{
                        width: '70%',
                    }}
                />
            </div>
            <SetNewPassword setErrorMessage={setErrorMessage} setIsLoading={setIsLoading} username={email}
                            isForgotten={true} confirmationCode={confirmationCode}/>

        </div>)
    }

    const renderForm = () => {
        if (codeSent) {
            return resetWithCode()
        } else {
            return askForEmail()
        }
    }

    return renderForm()
}


export default LoginForm;


