import React, {useEffect, useMemo, useRef, useState} from 'react';
import DataTable from "../../Components/DataTable/DataTable";
import BarChart from "../../Components/BarChart/BarChart";
import * as d3 from "d3";
import GoogleMapReact from 'google-map-react';
import PlaceIcon from '@mui/icons-material/Place';
import Silo from "../../Components/Silo/Silo";
import Dial from "../../Components/Dial/Dial";
import {capitalize, MenuItem, Select} from "@mui/material";
import locations from "../../locations";
// import LineChart from "../../Components/LineChart/LineChart";
import silo from "../../Components/Silo/Silo";
import VerticalBar from "../../Components/VericalBar/VerticalBar";
import {LineChart} from '@mui/x-charts/LineChart';
import {DataGrid} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import moment from "moment";

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const horizontalPalette = ['#E66C37', '#5BD667', '#12239E', '#D9B300', '#4092FF', '#E044A7', '#744EC2', '#15C6F4', '#1AAB40', '#F472D0', '#197278', '#FF8080', '#6B007B', '#D64550'];

const verticalPalette = ['rgba(188,194,197,0.45)']

function formatValue(value) {
    let formattedValue;
    if (value < 1) {
        formattedValue = value.toFixed(2);
    } else {
        formattedValue = d3.format('.4s')(value);
    }
    return formattedValue.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
}


const styles = {
    outerDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '2vh'
        // backgroundColor: 'rgba(255,255,255,0.9)'
    }, innerDiv: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: '96vw',
    }, columnFlexCenter: {
        display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'
    }, rowFlexSpaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '18vh',
        maxHeight: '18vh',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4vh',
        elevation: 10,
        marginTop: '4vh'

    }, itemDiv: {
        width: '15%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        borderRadius: 10,
        fontSize: '0.8vw',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        backgroundColor: 'white',
        fontFamily: "montserrat, sans-serif",
    }, labelStyle: {
        margin: 5, fontSize: '1em',
        maxLines: 1
    }, chartContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '55%',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        borderRadius: 10,
        padding: 10,
        boxSizing: 'border-box',
        backgroundColor: 'rgba(255,255,255,.3)',

    }, chartArea: {
        display: 'flex', flexDirection: 'row', height: 'calc(100% - 20px)', width: '100%',
    }, yAxisLabel: {
        // height: '100%',
        width: '2%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // transformOrigin: 'left bottom',

        // marginRight: 20,
        // marginLeft: 5

    }, yAxisText: {
        transform: 'rotate(-90deg) translate(-200%, -0%)  ',
        whiteSpace: 'nowrap',
        // fontSize: '1.2em',
        fontFamily: 'acumin-pro, sans-serif',
        // position: 'absolute',
        // top: '50%',
        // left:' 50%',
    }, chartDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 'calc(98% - 20px)',
        paddingLeft: 20,
        height: '98%',
        borderRadius: 10,
    }, dataTableContainer: {
        width: '100%',
        // overflow: 'auto',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        height: '52vh',
        maxHeight: '52vh',
        marginBottom: '8vh',
        marginTop: '4vh',

    }
};


const OuterContainer = ({children}) => {
    return <div style={styles.outerDiv}>{children}</div>;
};

const InnerContainer = ({children}) => {
    return <div style={styles.innerDiv}>{children}</div>;
};
const Marker = ({lat, lng, name}) => (
    <div style={{
        position: 'absolute',
        transform: 'translate(-50%, -100%)',  // Centers the marker
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <div style={{
            color: 'black',
            fontSize: '12px',  // Using fixed pixel size for text
            backgroundColor: 'white',
            borderRadius: 5,
            padding: 5,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        }}>
            {name}
        </div>
        <PlaceIcon style={{
            color: 'red',
            fontSize: '24px',  // Using fixed pixel size for icon
        }}/>
    </div>
);


const SummarySection = ({summaryData}) => {
    return (<div style={styles.rowFlexSpaceBetween}>
        {Object.entries(summaryData).map(([key, value]) => {
            if (key !== 'Location') return (
                <div style={styles.itemDiv}>
                    <div>{key}</div>
                    <div style={{
                        fontSize: '2vw', fontFamily: '', paddingTop: 10
                    }}>{value}</div>
                </div>
            )
            else {
                return (
                    <div style={{...styles.itemDiv}}>
                        <div style={{height: '100%', width: '100%'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: 'AIzaSyAMbGyE-dp9LIvL4scaCw3jV63J5OQ4MT0'}}
                                defaultCenter={{
                                    lat: summaryData['Location'][0]?.lat ?? 0,
                                    lng: summaryData['Location'][0]?.lon ?? 0
                                }}
                                defaultZoom={6}
                                options={{
                                    zoomControl: false,
                                    mapTypeControl: false,
                                    streetViewControl: false,
                                    keyboardShortcuts: false,
                                }}
                            >
                                {summaryData['Location'].map(site => (
                                    <Marker
                                        key={site.name} // Assuming each site has a unique name for key prop
                                        lat={site.lat}
                                        lng={site.lon}
                                        name={site.name}
                                    />
                                ))}
                            </GoogleMapReact>

                        </div>
                    </div>
                )
            }
        })}`
    </div>);
};


const Label = ({children, value, onChange, period}) => (
    <label style={{...styles.labelStyle, display: 'inline-block', whiteSpace: 'nowrap'}}>
        <input type="radio" value={value} checked={period === value} onChange={onChange}/>
        {children}
    </label>
);

const PrimaryChart = ({style, title, xLabel, yLabel, isLoading, currentPlot, containerRef, index}) => {
    return (<div key={title} style={style}>
        <div style={{textAlign: 'center', fontWeight: 'bold'}}>
            {title}
        </div>
        <div style={styles.chartArea}>
            <div style={styles.yAxisLabel}>
                <span style={styles.yAxisText}>{yLabel}</span>
            </div>
            <div style={{
                ...styles.chartDiv,
                filter: isLoading ? 'blur(2px)' : 'none',
                backgroundColor: isLoading ? 'white' : 'transparent',
                flexGrow: 1,
                overflow: 'visible'

            }} ref={(el) => {
                containerRef[parseInt(index)] = {current: el};
                return containerRef[index]
            }}>

                {currentPlot}
                <div style={{height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                        <span style={{
                            fontFamily: 'acumin-pro, sans-serif', fontSize: '1.2vw',

                        }}>{xLabel}</span>
                </div>
            </div>
        </div>
    </div>);
};

const BarChartSection = ({
                             location,
                             transactionData,
                             isLoading,
                             currentPlot,
                             siloPercent,
                             containerRef,
                             containerRef2,
                             containerRef4,
                             siloLevels,
                             index
                         }) => {

    const [selectedSilo, setSelectedSilo] = useState(Object.keys(siloLevels)[0]);
    const [siloRows, setSiloRows] = useState([]);


    useEffect(() => {
        let rows = transactionData[0].filter(item => `${item['Serial']}_${item['Name']}` === (selectedSilo ?? Object.keys(siloLevels)[0]));
        setSiloRows(rows);
    }, [selectedSilo, transactionData, selectedSilo, siloLevels]);

    return (<div style={{
        width: '100%',
        height: '40vh',
        maxHeight: '40vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        boxSizing: 'border-box', // boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        marginBottom: '4vh',
        borderRadius: 10,
        backgroundColor: 'rgba(255, 255, 2555, 0.1)'

    }}>

        <div style={{
            width: '35vw',
            paddingLeft: '1vw',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 10,
            backgroundColor: 'rgba(255,255,255,.3)',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'

        }}>

            <div style={{
                position: 'absolute',
                top: 10,
                left: 10,
                fontSize: 20,
            }}>{location.split(' ').map(e => capitalize(e)).join(' ')}</div>

            <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '90%'}}>


                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                }}>


                    <div style={{
                        minWidth: '25%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginRight: '5%',
                        paddingLeft: '%'
                        // position: 'relative'
                    }} ref={containerRef4}>
                        {/*<div style={{marginBottom: '30%'}}>whack</div>*/}
                        <div style={{justifySelf: 'flex-start', position: 'absolute', top: '13%', fontSize: '1em'}}>Site
                            Total (Tons)
                        </div>
                        <div id={`dial_${index}`}
                             style={{
                                 // marginTop: '-33%',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 alignItems: 'center',
                             }}>
                            <div
                                style={{textAlign: 'right', flex: 1}}>
                                {siloPercent?.['capacity'].toFixed(1) ?? 0}T
                            </div>
                            {/*<div style={{fontWeight: 'bold', paddingTop: 5}}>{siloPercent?.['level'].toFixed(1) ?? 0}T*/}
                            {/*</div>*/}
                            <VerticalBar containerRef={containerRef4}
                                         percentFull={siloPercent?.level ? siloPercent['level'] / siloPercent['capacity'] : 0}/>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                <div style={{textAlign: 'center', flex: 1}}>Empty</div>

                            </div>
                        </div>
                    </div>
                    <div style={{
                        width: '100%',
                        justifyContent: 'space-evenly',
                        height: '85%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        overflow: 'auto'
                    }}>
                        {
                            Object.keys(siloLevels).map((item, levelIndex) => (
                                <div style={{
                                    // width: `${100 / Math.min(Object.keys(siloLevels).length, 4)}%`,
                                    // width: '50%',
                                    maxWidth: '45%',
                                    width: '45%',
                                    height: 'calc(95% - 10px)',
                                    maxHeight: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                    paddingTop: levelIndex > 1 ? 20 : 0,
                                }}>

                                    {<div style={{
                                        textAlign: 'center',
                                        // marginRight: 10,
                                        width: '100%',
                                        paddingBottom: 5
                                    }}>{siloLevels[item].level.toFixed(1) + '/' + siloLevels[item].capacity.toFixed(1)}T</div>}

                                    <div style={{
                                        width: `100%`,
                                        height: '90%',
                                        alignSelf: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: "relative"
                                    }}
                                         ref={containerRef2}>

                                        <Silo
                                            level={siloLevels[item].level}
                                            capacity={siloLevels[item].capacity}
                                            color={horizontalPalette[levelIndex]}
                                            containerRef={containerRef2}
                                        />
                                    </div>
                                    {<div style={{
                                        fontWeight: 'bold',
                                        color: '#002741',
                                        marginBottom: 10,
                                        borderBottom: `3px solid ${horizontalPalette[levelIndex]}`,
                                    }}>{item.split('_')[1]}</div>}

                                    {
                                        <div style={{width: '100%', position: 'relative'}}>
                                            {/*{levelIndex === 0 && <div style={{*/}
                                            {/*    position: 'absolute',*/}
                                            {/*    left: '-150%',*/}
                                            {/*    top: '25%'*/}
                                            {/*}}>Last Online:</div>*/}
                                            {/*}*/}
                                            <div style={{
                                                border: '1px solid grey',
                                                borderRadius: 5,
                                                textAlign: 'center',
                                                // marginRight: 10,
                                                width: '100%',
                                                padding: 3,
                                            }}>{siloLevels[item]['Date-Time']}</div>
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>


        </div>


        <div style={{
            width: '35%',
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            fontSize: '0.8em',
            overflowX: 'hidden'
        }}>

            {/*<DataGrid rows={siloRows} columns={transactionData[1].concat(*/}
            {/*    [{renderHeader: () =>  <Select*/}
            {/*        style={{fontSize: '0.9em', padding: 0}}*/}
            {/*        onChange={(e) => setSelectedSilo(e.target.value)}*/}
            {/*        value={selectedSilo}*/}
            {/*    >*/}
            {/*        {Object.keys(siloLevels).map((item, index) => (*/}
            {/*            <MenuItem key={index} value={item}>{item.split('_')[1]}</MenuItem>*/}
            {/*        ))}*/}
            {/*    </Select>, field: 'None', sortable: false}]*/}
            {/*)} autoHeight={true} />*/}

            <DataTable data={siloRows}
                       dataModel={[{
                           groupBy: 'Date-Time',
                           equality: (item) => item.split(',')[0],
                           collapsedFormat: (item) => item.split(',')[1],
                       }]}
                       groupRules={{
                            'Date-Time': {individualFormat: (item) => item.split(',')[0]},
                       }}

                       formatGroup={(item) => item.split(',')[1]}
                       columns={transactionData[1].concat([<Select
                           style={{fontSize: '0.9em', padding: 0}}
                           onChange={(e) => setSelectedSilo(e.target.value)}
                           value={selectedSilo}
                       >
                           {Object.keys(siloLevels).map((item, index) => (
                               <MenuItem key={index} value={item}>{item.split('_')[1]}</MenuItem>
                           ))}
                       </Select>])}
                       initialSort={{key: 'epoch', direction: 'descending'}}

            />
        </div>

        {/*<div style={{*/}
        {/*    display: 'flex',*/}
        {/*    flexDirection: 'column',*/}
        {/*    alignItems: 'flex-start',*/}
        {/*    justifyContent: 'flex-start',*/}
        {/*    height: '100%',*/}
        {/*    paddingTop: 30,*/}


        {/*}}>*/}

        {/*    {periods.map((value) => (*/}
        {/*        <Label period={period} key={value} value={value} onChange={() => setPeriod(value)}>*/}
        {/*            {value.charAt(0).toUpperCase() + value.slice(1)}*/}
        {/*        </Label>))}*/}
        {/*</div>*/}

        <PrimaryChart
            // xLabel={'Date'}
            yLabel={'Mass (Tons)'}
            title={<div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', // Distribute space between items
                alignItems: 'center', // Align items vertically in the center
                width: '100%',

            }}>
                <div style={{flexGrow: 1, textAlign: 'center'}}> {/* Center the text */}
                    Silo Level By Date
                </div>
            </div>

            }
            style={{...styles.chartContainer, flexGrow: 1, maxHeight: '100%', boxShadow: ''}}
            index={index}
            isLoading={isLoading}
            currentPlot={currentPlot}
            containerRef={containerRef}/>

    </div>);
};


const precomputeDateRanges = (dateRanges) => {
    return dateRanges.map(range => {
        const [start, end] = range.split(' - ').map(d => dayjs(d));
        return {start, end, range};
    });
};

const generateDateRange = (startDate, endDate, period) => {
    let dates = [];
    let currentDate = dayjs(startDate).valueOf();
    const endTimestamp = dayjs(endDate).valueOf();

    while (currentDate <= endTimestamp) {
        let nextDate, label;

        switch (period) {
            case 'hour':
                nextDate = dayjs(currentDate).add(1, 'hour').valueOf();
                label = currentDate;
                break;
            case 'day':
                nextDate = dayjs(currentDate).add(1, 'day').valueOf();
                label = currentDate;
                break;
            case 'week':
                nextDate = dayjs(currentDate).add(1, 'week').valueOf();
                label = `${currentDate} - ${nextDate - 1}`
                break;
            case 'month':
                nextDate = dayjs(currentDate).add(1, 'month').valueOf();
                label = currentDate;
                break;
            default:
                throw new Error('Invalid period specified');
        }

        dates.push({start: currentDate, end: nextDate - 1, label});
        currentDate = nextDate;
    }

    return dates;
};

const formatDateLabel = (timestamp, period) => {
    let date
    //if timestamp is a string
    if (typeof timestamp !== 'string') {
        date = dayjs(timestamp);
    }
    switch (period) {
        case 'hour':
            return date.format('HH DD/MM/YYYY');
        case 'day':
            return date.format('DD/MM/YYYY');
        case 'week':
            let [start, end] = timestamp.split(' - ').map(e => parseInt(e))
            return `${dayjs(start).format('DD/MM/YYYY')} - ${dayjs(end).format('DD/MM/YYYY')}`;
        case 'month':
            return date.format('MM/YYYY');
        default:
            return date.format('DD-MM-YYYY HH:mm');
    }
};

const getDateLabel = (timestamp, dateRanges) => {
    for (const {start, end, label} of dateRanges) {
        if (timestamp >= start && timestamp <= end) {
            return label;
        }
    }
};

const getDateRange = (data) => {
    let min = dayjs().valueOf();
    let max = 0;
    data.forEach((item) => {
        let date = dayjs(item['Date-Time']).valueOf();
        if (date < min) {
            min = date;
        }
        if (date > max) {
            max = date;
        }
    });

    return [min, max];
};


const getEmulsionPerAsset = (data) => {
    let final = data.reduce((acc, item) => {
        let sensiMass = item['Operations']['sensiMassKG'];
        let waterMass = item['Operations']['waterMassKG'];
        let emulMass = item['Operations']['emulMassKG'];
        let serial = item['Name'] || item['Serial'];
        if (!acc[serial]) {
            acc[serial] = {

                x: serial, y: [emulMass, sensiMass, waterMass],
            };
        } else {
            acc[serial].y[0] += emulMass
            acc[serial].y[1] += sensiMass
            acc[serial].y[2] += waterMass
        }
        return acc;
    }, {});


    final = Object.fromEntries(Object.entries(final).sort(([, a], [, b]) => b.y.reduce((acc, v) => acc + v, 0) - a.y.reduce((acc, v) => acc + v, 0)));
    return Object.values(final)
}

const getSiloArray = (item) => {
    let silos = []
    let siloKeys = Object.keys(item).filter(key => key.startsWith('silos'));
    siloKeys.forEach((key) => {
        let idx = key.split('_')[0].slice(-1);
        let silo = silos[idx] || {};
        let value = item[key];
        let newKey = key.split('_')[1];
        silo[newKey] = value;
        silos[idx] = silo;
    });
    return silos
}

const getCurrentSiloLevel = (data) => {
    let uniqueSerials = new Set(data.map(item => item['Serial']));
    let lastEntry = {};
    uniqueSerials.forEach((serial) => {
        let filteredData = data.filter((item) => item['Serial'] === serial);
        let sortedData = filteredData.sort((a, b) => new dayjs(b['Date-Time']) - new dayjs(a['Date-Time']));
        const visitedSilos = new Set(); // Use a Set to efficiently check for uniqueness

        for (const data of sortedData) {
            const silos = getSiloArray(data);

            if (silos.length > 0) {
                silos.forEach(silo => {
                    if (silo.name && silo.name !== 'null' && !visitedSilos.has(silo.name)) {
                        visitedSilos.add(silo.name); // Adds to the set, which ensures uniqueness efficiently
                        const id = `${serial}_${silo.name}`;
                        lastEntry[id] = {
                            level: parseFloat(silo.massKG) / 1000 || 0,
                            capacity: parseFloat(silo.maxMassKg) || 0,
                            'Date-Time': dayjs(data['Date-Time']).format('DD/MM/YYYY')
                        };
                    }
                });
            }

            // Check if we've visited all possible unique silos after processing each entry
            // if (visitedSilos.size === sortedData[0].Inventory.silos.length) {
            //     break;
            // }
        }

    });
    return lastEntry
}
const getChartData = (data) => {
    let final = data.reduce((acc, item) => {
        let massKG = item.Inventory.silos.reduce((sum, silo) => {
            let mass = parseFloat(silo.massKG);
            return sum + (isNaN(mass) ? 0 : mass);
        }, 0);

        if (!acc[item.Serial]) {
            acc[item.Serial] = {
                x: item.Serial,
                y: massKG,
            };
        } else {
            let key = 'y';
            acc[item.Serial][key] += massKG;
        }
        return acc;
    }, {});

    let res = Object.values(final)
    res = res.map(item => {
        return {...item, y: [item.y]}
    })
    return res
}


const InventorySilo = React.memo(({data, screen}) => {
    const [periods, setPeriods] = useState(['hour', 'day', 'month']);
    const allData = useRef({});
    const [isLoading, setIsLoading] = useState(false); // New state for loading
    const [siloLevels, setSiloLevels] = useState({});
    const [siloPercent, setSiloPercent] = useState(0);
    let allPlots = useRef({});
    let uniqueLocations = useMemo(() => new Set(data.map(item => item['Site'])), [data]);
    const containerRef2 = Array(10).fill(useRef(null));
    const containerRef4 = Array(uniqueLocations.size).fill(useRef(null));
    let initialPeriods = {}
    let lineData = useRef({})
    const [period, setPeriod] = useState(initialPeriods);
    const [containerRef, setContainerRef] = useState(Array(uniqueLocations.size).fill(null));
    const contentRef = useRef(null);
    const [renderContent, setRenderContent] = useState(screen);

    useEffect(() => {
        if (!contentRef.current && screen) {
            setRenderContent(true);
        }
        if (!screen) {
            setRenderContent(false);
        }
    }, [screen]);


    useEffect(() => {
        setRenderContent(screen);
    }, [screen]);


    useEffect(() => {
        uniqueLocations.forEach((location) => {
            initialPeriods[location] = 'day'
        })
        setPeriod(initialPeriods)
    }, [uniqueLocations]);

    const getSummaryData = (data) => {
        let summary = {'Total Silo Capacity (Tons)': 0}

        let avgCapacityUtilization = 0;
        let numSilos = 0

        Object.keys(siloPercent).forEach((key) => {
            summary['Total Silo Capacity (Tons)'] += siloPercent[key]['capacity']
            avgCapacityUtilization += siloPercent[key]['level'] / siloPercent[key]['capacity']
        });


        summary['Current Total Available (Tons)'] = summary['Total Silo Capacity (Tons)'];

        Object.keys(siloLevels).forEach((key) => {
            Object.keys(siloLevels[key]).forEach((silo) => {
                summary['Current Total Available (Tons)'] -= siloLevels[key][silo]['level']
                numSilos += 1
            })
        })

        let locationData = Array.from(uniqueLocations || [])
            .map(location => locations[location] ? {...locations[location], name: location} : undefined)
            .filter(item => item !== undefined);  // Remove undefined entries


        summary['Current Total Available (Tons)'] = summary['Current Total Available (Tons)'].toFixed(1)
        summary['Total Silo Capacity (Tons)'] = summary['Total Silo Capacity (Tons)'].toFixed(1)
        summary['Average Refill Per Week'] = 14.46
        summary['Average Capacity Utilization'] = (avgCapacityUtilization / Object.keys(siloPercent).length * 100).toFixed(1) + '%'
        summary['Number of Silos in Operation'] = numSilos
        summary['Location'] = locationData
        return summary;
    }

    // const summaryData = useMemo(() => getSummaryData(data), [siloLevels, siloPercent]);


    useEffect(() => {

        let levels = {}
        let percentages = {}
        uniqueLocations.forEach((location) => {
            levels[location] = getCurrentSiloLevel(data.filter(item => item['Site'] === location))
            let num = 0;
            let denom = 0;
            Object.keys(levels[location]).forEach((key, index) => {
                if (key) {
                    num += levels[location][key]['level']
                    denom += levels[location][key]['capacity']
                }
            })
            percentages[location] = {}
            percentages[location]['level'] = num
            percentages[location]['capacity'] = denom
        })
        setSiloLevels(levels)
        setSiloPercent(percentages)
    }, [data, period]);


    function adjustDate(date, period) {
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        if (period === 'day' || period === 'month') {
            date.setHours(0);
        }
        if (period === 'month') {
            date.setDate(1);
        }

        return date;
    }


    useEffect(() => {

        //if date range is greater than 2 days, remove hour option
        let [min, max] = getDateRange(data);
        let diff = (max - min) / (1000 * 60 * 60 * 24);
        let newPeriods = []
        if (diff > 2) {
            newPeriods = ['day', 'month']

        } else {
            newPeriods = ['hour', 'day', 'month'];

        }
        newPeriods = ['hour', 'day', 'month'];

        setPeriods(newPeriods)
        newPeriods.forEach((value, idx1) => {
            // if(value !== 'period'){
            //     return
            // }

            Array.from(uniqueLocations).forEach((location, idx2) => {
                if (idx1 === 0) {
                    lineData.current[location] = getLineData(data.filter(item => item['Site'] === location))
                }
                if (period[location] !== value) {
                    return
                }
                let filteredData = data.filter(item => item['Site'] === location);
                let tankLvl = getTankLvlPerPeriod(filteredData, value)
                if (!allData.current[value]) {
                    allData.current[value] = {}
                    allPlots.current[value] = {}
                }
                allData.current[value][location] = tankLvl
                allPlots.current[value][location] = createBarChart(location, value, containerRef[idx2])


            })
        });

    }, [data, period]);


    const periodRef = useRef(period);
    const dataRef = useRef(data);

    //     const getInitialOverlays = () => {
    //     let overlays = []
    //     let colors = horizontalPalette
    //
    //     let serials = new Set()
    //     data.forEach((item) => {
    //         serials.add(item['Name'] || item['Serial'])
    //     })
    //     let emulsionPerAsset = getEmulsionPerAsset(data)
    //
    //     emulsionPerAsset.forEach((item, index) => {
    //         overlays.push({
    //             name: item['x'],
    //             data: getEmulsionPerAssetPerPeriod(data, period, item['x']),
    //             color: colors[index % colors.length]
    //         })
    //     })
    //     return overlays
    // }

    const stackBars = (data) => {
        let colors = horizontalPalette
        let overlays = []
        let frequency = data.result.length / data.numBars
        for (let i = 0; i < data.names.size; i++) {
            let res = [];
            for (let j = 0; j < frequency; j++) {
                res.push({x: data.result[i * frequency + j].x, y: data.result[i * frequency + j].y[0]});
            }
            overlays.push({
                name: Array.from(data.names)[i],
                data: res,
                color: colors[i]
            })
        }
        return overlays
    }


function interpolateArray(arr) {
    // Helper function to find the next non-null value
    function findNextNonNull(arr, startIndex) {
        for (let i = startIndex; i < arr.length; i++) {
            if (arr[i] !== null) {
                return {value: arr[i], index: i};
            }
        }
        return null;
    }

    // Helper function to find the previous non-null value
    function findPreviousNonNull(arr, startIndex) {
        for (let i = startIndex; i >= 0; i--) {
            if (arr[i] !== null) {
                return {value: arr[i], index: i};
            }
        }
        return null;
    }

    // Interpolating the array
    let result = arr.slice(); // Copy the array to avoid mutating the original

    // Find the first and last non-null indices
    let firstNonNullIndex = result.findIndex(value => value !== null);
    let lastNonNullIndex = result.length - 1 - result.slice().reverse().findIndex(value => value !== null);

    for (let i = 0; i < result.length; i++) {
        if (result[i] === null && i >= firstNonNullIndex && i <= lastNonNullIndex) {
            let prev = findPreviousNonNull(result, i);
            let next = findNextNonNull(result, i);

            if (prev && next) {
                let interpolatedValue = prev.value + (next.value - prev.value) * ((i - prev.index) / (next.index - prev.index));
                result[i] = interpolatedValue;
            }
        }
    }

    return result;
}
    const createBarChart = (location, period, containerRef) => {
        if (!lineData.current[location]) {
            return null;
        }


        // const series = lineData.current[location].map(item => item.data) ?? [];
        const xDataSet = new Set();
        const yData = [];

        // Collect all unique x values and initialize yData arrays
        lineData.current[location].forEach(seriesItem => {
            const ySeries = [];
            seriesItem.data.forEach(item => {
                xDataSet.add(item.x);
                ySeries.push(item);
            });


            yData.push({ySeries, stack: 'total', area: true, stackOffset: 'none', showMark: false, connectNulls: false});
        });

        const xData = Array.from(xDataSet).sort((a, b) => a - b);

        // Create yData arrays with nulls for missing values
        yData.forEach(yItem => {
            yItem.data = xData.map(x => {
                const found = yItem.ySeries.find(item => item.x === x);
                return found ? found.y / 1000 : null;
            });
            yItem.data = interpolateArray(yItem.data);
            delete yItem.ySeries; // Remove temporary ySeries array
        });


        return (
            <LineChart
                xAxis={[{data: xData, label: 'Date', valueFormatter: v => dayjs(v).format('DD/MM/YYYY')}]}
                series={yData}
                colors={horizontalPalette}
                skipAnimation={true}
            />
        );
    };


    // const periodTotalBarChart = <BarChart
    //     id={'silo-bar-chart'}
    //     stacked={false}
    //     colorPalette={horizontalPalette.slice(0, allData.current[period]?.numBars ?? 0)}
    //     onHtmlExtract={(html) => periodYAxisRef.current = html}
    //     yaxisHtml={periodYAxisRef.current}
    //     xaxisLabel={'Date'}
    //     yaxisLabel={'Total Mass (kg)'}
    //     containerRef={containerRef}
    //     orientation={'vertical'}
    //     data={allData.current[period]?.result ?? []}
    //     keysInput={['Total']}
    //     dimensions={{width: '100%', height: '80%'}}
    //     ytickFormat={d3.format(".2s")}
    //     // overlayData={periodOverlays}
    // />

    useEffect(() => {
        periodRef.current = period;
    }, [period]);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);


    const getTransactions = (data) => {
        let rows = []
        const dateTimeFormatter = new Intl.DateTimeFormat('en-UK', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', second: '2-digit'
        });
        data.forEach(item => {
            const dateTime = new Date(item['Date-Time']);
            const hourKey = `${dateTime.getFullYear()}-${dateTime.getMonth()}-${dateTime.getDate()}-${dateTime.getHours()}`;

            let silos = getSiloArray(item);

            silos.forEach((silo, idx) => {

                let row = {
                    'id': `${item['Serial']}_${idx}_${item['Date-Time']}`,
                    'Serial': item['Serial'],
                    'Hardware': item['Serial'],
                    'Name': silo.name,
                    'Date-Time': dayjs(item['Date-Time']).format('DD/MM/YYYY, HH:mm:ss'), //dateTimeFormatter.format(dateTime
                    'epoch': item['Date-Time'],
                    'end': item.timestamps[item.timestamps.length - 1],
                    'Mass (kg)': silo.massKG,
                    'Level (%)': (() => {
                        if (!silo.massKG || !silo.maxMassKg) {
                            return null
                        }
                        const quantity = (silo?.massKG / 10 ?? 0) / (silo?.maxMassKg ?? 1);
                        return isNaN(quantity) ? 0 : quantity.toFixed(0);
                    })(),

                }
                if (silo.name ) {
                    rows.push(row)


                    if (item.timestamps.length > 0) {
                        item.timestamps.forEach((timestamp, idx) => {
                            let newRow = {...row};
                            newRow['Date-Time'] = dayjs(timestamp * 1000).format('DD/MM/YYYY, HH:mm:ss');
                            newRow['epoch'] = timestamp * 1000;
                            rows.push(newRow)
                        })

                    }
                }


            });


            // if (!mostRecentEntries.has(hourKey) || new Date(mostRecentEntries.get(hourKey)['Date-Time']) < dateTime) {
            //     mostRecentEntries.set(hourKey, entry);
            // }
        });

        // const rows = Array.from(mostRecentEntries.values()).map(entry => ({
        //     ...entry,
        //     'Date-Time': new Date(entry['Date-Time']).toLocaleString()
        // }));

        let cols = ['Date-Time', 'Mass (kg)', 'Level (%)'];
        // cols = cols.map(col => ({field: col, headerName: col}));

        return [rows, cols];
    };

    const getLineData = (data) => {
        let uniqueSerials = new Set(data.map(item => item['Serial']));
        let uniqueSilos = new Set();
        let res = {};

        let startDate = new Date(data[0]['Date-Time']);
        let endDate = new Date(data[data.length - 1]['Date-Time']);
        let allDates = generateDateRange(startDate, endDate, 'hour');


        uniqueSerials.forEach((serial) => {
            let filteredData = data.filter(item => item['Serial'] === serial);
            filteredData.forEach(item => {
                let silos = getSiloArray(item);
                silos.forEach(silo => {
                    if (silo.name && silo.name !== 'null') {
                        let id = `${serial}_${silo.name}`;
                        if (!uniqueSilos.has(id)) {
                            uniqueSilos.add(id);
                            res[id] = {data: [], name: silo.name};
                        }
                        let mass = parseFloat(silo.massKG);
                        if (!isNaN(mass)) {
                            let len = res[id].data.length;
                            if (len === 0 || Math.abs((mass - res[id].data[len - 1].y) / res[id].data[len - 1].y) > 0.01) {
                                res[id].data.push({x: getDateLabel(item['Date-Time'], allDates), y: mass});
                            }
                        }
                    }
                });
            });
        });


        console.log('laan', res)


        return Object.values(res);
    };


    const getTankLvlPerPeriod = (data, period) => {
        let uniqueSerials = new Set(data.map(item => item['Serial']));
        let uniqueSilos = new Set();

        let res = []
        let {startDateString, endDateString} = data.reduce((acc, item) => {
            if (item['Date-Time'] < acc.startDateString) acc.startDateString = item['Date-Time'];
            if (item['Date-Time'] > acc.endDateString) acc.endDateString = item['Date-Time'];
            return acc;
        }, {startDateString: data[0]['Date-Time'], endDateString: data[0]['Date-Time']});

        let startDate = new Date(startDateString);
        let endDate = new Date(endDateString);
        startDate = adjustDate(startDate, period);
        endDate = adjustDate(endDate, period);
        let allDates = generateDateRange(startDate, endDate, period);

        uniqueSerials.forEach((serial) => {
            let filteredData = data.filter((item) => item['Serial'] === serial).sort((a, b) => b['Date-Time'] - a['Date-Time']);
            let finalArr = {}

            filteredData.forEach(item => {
                let date = item['Date-Time'];

                let dateLabel = formatDateLabel(date, period);
                let silos = getSiloArray(item);
                silos.forEach((silo, idx) => {
                    if (silo.name && silo.name !== 'null') {
                        let id = `${serial}_${silo.name}`
                        if (!uniqueSilos.has(id)) {
                            uniqueSilos.add(`${serial}_${silo.name}`)
                        }
                        let mass = parseFloat(silo.massKG);
                        if (!isNaN(mass)) {
                            if (!finalArr[id]) {
                                finalArr[id] = allDates.reduce((acc, dateLabel) => {
                                    acc[dateLabel] = {x: dateLabel, y: [0]};
                                    return acc;
                                }, {});
                            }
                            finalArr[id][dateLabel] = {x: dateLabel, y: [mass]}
                        }

                    }
                });

            });

            Object.keys(finalArr).forEach((key) => {
                res.push(...Object.values(finalArr[key]).sort((a, b) => a.x.localeCompare(b.x)))
            })
        });

        let newRes = res.reduce((acc, curr) => {
            const existing = acc.find(item => item.x === curr.x);
            if (existing) {
                existing.y[0] += curr.y[0];
            } else {
                acc.push({x: curr.x, y: [curr.y[0]]});
            }
            return acc;
        }, []);


        return {result: res, totals: newRes, numBars: uniqueSilos.size, names: uniqueSilos}
    }

    let transactionData = useMemo(() => {
        let transactionData = {}
        Array.from(uniqueLocations).forEach((location, idx) => {
            transactionData[location] = getTransactions(data.filter(item => item['Site'] === location))
        })
        return transactionData
    }, [data]);

    // let barchartSection = useMemo(() => {
    //         return Array.from(uniqueLocations).sort().map((location, idx) => {
    //
    //             return <BarChartSection
    //                 location={location}
    //                 transactionData={transactionData[location]}
    //                 period={period[location]}
    //                 periods={periods}
    //                 setPeriod={(p) => setPeriod(oldPeriods => {
    //                     return {...oldPeriods, [location]: p}
    //                 })}
    //                 isLoading={isLoading}
    //                 currentPlot={allPlots.current[period[location]]?.[location] ?? null} // replace with your plot component
    //                 containerRef={containerRef}
    //                 containerRef2={containerRef2}
    //                 containerRef4={containerRef4}
    //                 siloLevels={siloLevels?.[location] ?? []}
    //                 siloPercent={siloPercent[location]}
    //                 index={idx}
    //             />
    //
    //         })
    //     },
    //     [transactionData, screen]
    // )

    useEffect(() => {
        contentRef.current = (
            Array.from(uniqueLocations).sort().map((location, idx) => {

                return <div style={{width: '95vw', marginTop: '2vh'}}>
                    <BarChartSection
                        location={location}
                        transactionData={transactionData[location]}
                        period={period[location]}
                        periods={periods}
                        setPeriod={(p) => setPeriod(oldPeriods => {
                            return {...oldPeriods, [location]: p}
                        })}
                        isLoading={isLoading}
                        currentPlot={allPlots.current[period[location]]?.[location] ?? null} // replace with your plot component
                        containerRef={containerRef}
                        containerRef2={containerRef2}
                        containerRef4={containerRef4}
                        siloLevels={siloLevels?.[location] ?? []}
                        siloPercent={siloPercent[location]}
                        index={idx}
                    />
                </div>

            })
        )
    }, [transactionData, siloLevels]);


    return renderContent ? contentRef.current : null
})

export default InventorySilo;
