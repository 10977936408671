import React, {useEffect, useMemo, useRef, useState} from 'react';
import '../PlotCollection/PlotsScreen.css';
import DataTable from "../../Components/DataTable/DataTable";
import BarChart from "../../Components/BarChart/BarChart";
import * as d3 from "d3";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GoogleMapReact from 'google-map-react';
import PlaceIcon from '@mui/icons-material/Place';
import locations from "../../locations";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import moment from "moment";

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const horizontalPalette = ['#E66C37', '#5BD667', '#12239E', '#D9B300', '#4092FF', '#E044A7', '#744EC2', '#15C6F4', '#1AAB40', '#F472D0', '#197278', '#FF8080', '#6B007B', '#D64550'];

const verticalPalette = ['rgba(188,194,197,0.45)']

function formatValue(value) {
    let formattedValue;
    if (value < 1) {
        formattedValue = value.toFixed(2);
    } else {
        formattedValue = d3.format('.4s')(value);
    }
    return formattedValue.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
}


const styles = {
    outerDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }, innerDiv: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: '96vw',
    }, columnFlexCenter: {
        display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'
    }, rowFlexSpaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '18vh',
        maxHeight: '18vh',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4vh',
        elevation: 10,
        marginTop: '4vh'

    }, itemDiv: {
        width: '15%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        borderRadius: 10,
        fontSize: '0.8vw',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        backgroundColor: 'rgba(255,255,255,.3)',
        fontFamily: "montserrat, sans-serif",
    }, labelStyle: {
        margin: 5, fontSize: '1em'
    }, chartContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '55%',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        borderRadius: 10,
        padding: 10,
        boxSizing: 'border-box',
        backgroundColor: 'rgba(255,255,255,.3)',

    }, chartArea: {
        display: 'flex', flexDirection: 'row', height: 'calc(100% - 20px)', width: '100%'
    }, yAxisLabel: {
        // height: '100%',
        width: '2%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // transformOrigin: 'left bottom',

        // marginRight: 20,
        // marginLeft: 5

    }, yAxisText: {
        transform: 'rotate(-90deg) translate(-200%, -0%)  ',
        whiteSpace: 'nowrap',
        fontSize: '1.2vw',
        fontFamily: 'acumin-pro, sans-serif',
        // position: 'absolute',
        // top: '50%',
        // left:' 50%',
    }, chartDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 'calc(98% - 20px)',
        paddingLeft: 20,
        height: '98%',
        borderRadius: 10,
    }, dataTableContainer: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        height: '100%',
        // maxHeight: '52vh',
        marginBottom: '8vh',
        // marginTop: '4vh',

    }
};


const OuterContainer = ({children}) => {
    return <div style={styles.outerDiv}>{children}</div>;
};

const InnerContainer = ({children}) => {
    return <div style={styles.innerDiv}>{children}</div>;
};

const Marker = ({lat, lng, name}) => (
    <div style={{
        position: 'absolute',
        transform: 'translate(-50%, -100%)',  // Centers the marker
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <div style={{
            color: 'black',
            fontSize: '12px',  // Using fixed pixel size for text
            backgroundColor: 'white',
            borderRadius: 5,
            padding: 5,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        }}>
            {name}
        </div>
        <PlaceIcon style={{
            color: 'red',
            fontSize: '24px',  // Using fixed pixel size for icon
        }}/>
    </div>
);

const SummarySection = ({summaryData}) => {
    return (<div style={styles.rowFlexSpaceBetween}>
        {Object.entries(summaryData).map(([key, value]) => {
            if (key !== 'Location') return (
                <div style={styles.itemDiv}>
                    <div>{key}</div>
                    <div style={{
                        fontSize: '2vw', fontFamily: '', paddingTop: 10
                    }}>{value}</div>
                </div>
            )
            else {
                return (
                    <div style={{...styles.itemDiv}}>
                        <div style={{height: '100%', width: '100%'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: 'AIzaSyAMbGyE-dp9LIvL4scaCw3jV63J5OQ4MT0'}}
                                defaultCenter={{
                                    lat: summaryData['Location'][0]?.lat ?? 0,
                                    lng: summaryData['Location'][0]?.lon ?? 0
                                }}
                                defaultZoom={6}
                                options={{
                                    zoomControl: false,
                                    mapTypeControl: false,
                                    streetViewControl: false,
                                    keyboardShortcuts: false,
                                }}
                            >
                                {summaryData['Location'].map(site => (
                                    <Marker
                                        key={site.name + 'offloader'} // Assuming each site has a unique name for key prop
                                        lat={site.lat}
                                        lng={site.lon}
                                        name={site.name}
                                    />
                                ))}
                            </GoogleMapReact>
                        </div>
                    </div>
                )
            }
        })}
    </div>);
};

const getPlaceTransferPumpCycleData = (data) => {
    let cols = ['Date-Time', 'UID', 'RIG Name', "Receiver", 'Mass (Kg)'];

    // Use Intl.DateTimeFormat for efficient date formatting
    let rows = data.map(item => {
        return {
            "Date-Time": dayjs(item['Date-Time']).format('DD/MM/YYYY, HH:mm:ss'),
            "UID": item['UID'],
            "RIG Name": item['Name'],
            "Receiver": item['unitLoaded'] === 'None' ? 'Default' : item['unitLoaded'],
            "Mass (Kg)": item['unitMassKG']
        }
    });


    return [rows, cols];
}


//     let rows = data.map((item) => {
//         return {
//             'Site': item['Site'],
//             'Holes': item['numHole'],
//             'Mass (kg)': item['pumpMassKG'],
//             'Mass / Hole': item['pumpMassKG'] / item['numHole'],
//             'Start Time': 0,
//             'End Time': 0,
//             'Pump Run Time (Hr)': item['runtimeMS'] / (1000 * 60 * 60),
//
//         }
//     })
// }

const DataTableContainer = ({
                                data,
                                formatting,
                                title,
                                dataModel,
                                collapseEqaulity,
                                formatGroup,
                                groupRules,
                                firstColWidth,
                                dataMap
                            }) => {
    return (<div style={styles.dataTableContainer}>
        {title && <div className='table-header-display'>{title}</div>}

        <div style={{
            height: title ? '95%' : '100%',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
        }}>
            <DataTable data={data[0]}
                       columns={data[1]}
                       dataModel={dataModel}
                       formatting={formatting}
                       collapseEquality={collapseEqaulity}
                       formatGroup={formatGroup}
                       groupRules={groupRules}
                       dataMap={dataMap}
                       firstColWidth={firstColWidth}
            />
        </div>
    </div>);
};


const Label = ({children, value, onChange, period}) => {
    return (<label style={styles.labelStyle}>
        <input type="radio" value={value} checked={period === value} onChange={onChange}/>
        {children}
    </label>);
};

const PrimaryChart = ({style, title, xLabel, yLabel, isLoading, currentPlot, containerRef}) => {
    return (<div key={title} style={style}>
        <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.2vw'}}>
            {title}
        </div>
        <div style={styles.chartArea}>
            <div style={styles.yAxisLabel}>
                <span style={styles.yAxisText}>{yLabel}</span>
            </div>
            <div style={{
                ...styles.chartDiv,
                filter: isLoading ? 'blur(2px)' : 'none',
                backgroundColor: isLoading ? 'red' : 'transparent',
                flexGrow: 1,
                overflow: 'visible'

            }} ref={containerRef}>

                {currentPlot}
                <div style={{height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                        <span style={{
                            fontFamily: 'acumin-pro, sans-serif', fontSize: '1.2vw',

                        }}>{xLabel}</span>
                </div>
            </div>
        </div>
    </div>);
};

const BarChartSection = ({
                             isLoading,
                             currentPlot,
                             totalMassPumped,
                             assetTotalBarChart,
                             containerRef,
                             containerRef2,
                             period,
                             periods,
                             setPeriod,
                             setPeriodOverlays,
                             lineChart
                         }) => {
    return (<div style={{
        width: '100%',
        height: '47vh',
        maxHeight: '47vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        boxSizing: 'border-box', // boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        borderRadius: 10
    }}>

        <div style={{
            width: '65%',
            display: 'flex',
            flexDirection: 'row',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
            borderRadius: 10,
            backgroundColor: 'rgba(255,255,255,.3)',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                height: '100%',
                paddingTop: 30

            }}>
                {periods.map((value) => (
                    <Label period={period} key={value} value={value} onChange={() => setPeriod(value)}>
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                    </Label>))}
            </div>


            <PrimaryChart
                xLabel={'Date'}
                yLabel={'Mass (kg)'}
                title={<div style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', // Distribute space between items
                    alignItems: 'center', // Align items vertically in the center
                    width: '100%'
                }}>
                    <div style={{flexGrow: 1, textAlign: 'center'}}> {/* Center the text */}
                        Blasting Operation Totals By Date
                    </div>
                    <div
                        style={{
                            flexShrink: 0, paddingRight: 10
                        }}> {/* Prevent the icon from shrinking */}
                        <ClearAllIcon style={{cursor: 'pointer'}}
                                      onClick={() => setPeriodOverlays([])}/>
                    </div>
                </div>

                }
                style={{...styles.chartContainer, flexGrow: 1, maxHeight: '100%', boxShadow: '', fontSize: '0.65em'}}
                isLoading={isLoading}
                currentPlot={currentPlot}
                containerRef={containerRef}/>
        </div>


        <PrimaryChart
            xLabel={'Mass (kg)'}
            yLabel={'Asset'}
            title={(<div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    <div>
                        {'Asset Total (kg)'}
                    </div>

                </div>

                <div id={'mass-title'}
                     style={{display: 'flex', flexDirection: 'column', alignItems: 'end', paddingTop: 30}}>
                    {/*<div>*/}
                    {/*    <div style={{fontSize: '1.2vw', fontWeight: 'normal'}}>*/}
                    {/*        Mass Pumped (kg)*/}
                    {/*    </div>*/}
                    {/*    <div style={{fontWeight: 'normal', fontSize: '1.2vw'}}>*/}
                    {/*        {formatValue(totalMassPumped)}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>)}
            style={{
                ...styles.chartContainer,
                fontSize: '0.8em',
                width: '33%',
                // maxHeight: `calc(100% - ${document.getElementById('mass-title')?.clientHeight}px)`,
                maxHeight: '100%',
                // paddingBottom: document.getElementById('mass-title')?.clientHeight + 10,
            }}
            isLoading={isLoading}
            currentPlot={assetTotalBarChart}
            containerRef={containerRef2}/>


    </div>);
};

const pad = (num) => num.toString().padStart(2, '0');

const precomputeDateRanges = (dateRanges) => {
    return dateRanges.map(range => {
        let [start, end] = range.split(' - ').map(d => dayjs(d));
        start.hour(0).minute(0).second(0);
        if (end) {
            end = end.hour(23).minute(59).second(59);
        }
        return {start, end, range};
    });
};

const formatDate = (timestamp, period, dateRanges = []) => {
    const date = dayjs(timestamp);
    let precomputedRanges = precomputeDateRanges(dateRanges);

    switch (period) {
        case 'hour':
            return date.format('YYYY-MM-DD HH');
        case 'day':
            return date.format('YYYY/MM/DD');
        case 'week':
            for (const {start, end, range} of precomputedRanges) {
                if (date.isBetween(start, end, null, '[]')) {
                    return `${start.format('YYYY/MM/DD')} - ${end.format('YYYY/MM/DD')}`;
                }
            }
            console.log('Date not in range', precomputedRanges, date.format('YYYY/MM/DD HH mm'))
            return null;
        case 'month':
            return date.format('YYYY-MM');
        default:
            return date.format('DD-MM-YYYY HH:mm');
    }
};
// Updated generateDateRange function for context
const generateDateRange = (startDate, endDate, period) => {
    let dates = [];
    let currentDate = dayjs(startDate).valueOf();
    const endTimestamp = dayjs(endDate).valueOf();

    while (currentDate <= endTimestamp) {
        let nextDate, label;

        switch (period) {
            case 'hour':
                nextDate = dayjs(currentDate).add(1, 'hour').valueOf();
                label = currentDate;
                break;
            case 'day':
                nextDate = dayjs(currentDate).add(1, 'day').valueOf();
                label = currentDate;
                break;
            case 'week':
                nextDate = dayjs(currentDate).add(1, 'week').valueOf();
                label = `${currentDate} - ${nextDate - 1}`
                break;
            case 'month':
                nextDate = dayjs(currentDate).add(1, 'month').valueOf();
                label = currentDate;
                break;
            default:
                throw new Error('Invalid period specified');
        }

        dates.push({start: currentDate, end: nextDate - 1, label});
        currentDate = nextDate;
    }

    return dates;
};

const getDateLabel = (timestamp, dateRanges) => {
    for (const {start, end, label} of dateRanges) {
        if (timestamp >= start && timestamp <= end) {
            return label;
        }
    }
};

const formatDateLabel = (timestamp, period) => {
    let date
    //if timestamp is a string
    if (typeof timestamp !== 'string') {
        date = dayjs(timestamp);
    }
    switch (period) {
        case 'hour':
            return date.format('HH DD/MM/YYYY');
        case 'day':
            return date.format('DD/MM/YYYY');
        case 'week':
            let [start, end] = timestamp.split(' - ').map(e => parseInt(e))
            return `${dayjs(start).format('DD/MM/YYYY')} - ${dayjs(end).format('DD/MM/YYYY')}`;
        case 'month':
            return date.format('MM/YYYY');
        default:
            return date.format('DD-MM-YYYY HH:mm');
    }
};

const getDateRange = (data) => {
    let min = dayjs().valueOf();
    let max = 0;
    data.forEach((item) => {
        let date = dayjs(item['Date-Time']).valueOf();
        if (date < min) {
            min = date;
        }
        if (date > max) {
            max = date;
        }
    });

    return [min, max];
};


const adjustDate = (date, period) => {
    const d = dayjs(date);
    switch (period) {
        case 'hour':
            return d.startOf('hour').valueOf();
        case 'day':
            return d.startOf('day').valueOf();
        case 'week':
            return d.startOf('week').valueOf();
        case 'month':
            return d.startOf('month').valueOf();
        default:
            return d.valueOf();
    }
};


const getEmulsionPerAsset = (data) => {
    let x = 0;
    let final = data.reduce((acc, item) => {
        //TODO UPDATE RULES

        if (item['SourceType'] !== 'UG') return acc;

        let sensiMass = item['pumpMassKG'] < 500 ? item['pumpMassKG'] : 0;
        let waterMass = item['waterMassKG'] < 500 ? item['waterMassKG'] : 0;
        let emulMass = item['emulMassKG'] < 500 ? item['emulMassKG'] : 0;
        let serial = item['Name'] || item['Serial'];
        if (!acc[serial]) {
            acc[serial] = {
                x: serial, y: [emulMass, sensiMass, waterMass],
            };
        } else {
            acc[serial].y[0] += emulMass !== 'null' ? emulMass : 0;
            acc[serial].y[1] += sensiMass !== 'null' ? sensiMass : 0;
            acc[serial].y[2] += waterMass !== 'null' ? waterMass : 0;
        }
        return acc;
    }, {});


    final = Object.fromEntries(Object.entries(final).sort(([, a], [, b]) => b.y.reduce((acc, v) => acc + v, 0) - a.y.reduce((acc, v) => acc + v, 0)));

    return Object.values(final)
}


function InventoryOffloader({screen, data}) {
    const [period, setPeriod] = useState('day');
    const containerRef = useRef(null);
    const containerRef2 = useRef(null);
    const [periods, setPeriods] = useState(['hour', 'day', 'month']);
    const allData = useRef({});
    let uniqueLocations = useMemo(() => new Set(data.map(item => item['Site'])), [data]);
    const [isLoading, setIsLoading] = useState(false); // New state for loading
    const assetYAxisRef = useRef(null);
    const [renderContent, setRenderContent] = useState(false);
    const contentRef = useRef(null);
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        if (!contentRef.current && screen) {
            setRenderContent(true);
        }
        if (!screen) {
            setRenderContent(false);
        }
    }, [screen]);

    useEffect(() => {
        setRenderContent(screen);
    }, [screen]);

    const getOverviewData = (data) => {

        const cols = ['Site', 'Rig Name', 'Month', 'Blast Card', 'Holes', 'Mass (kg)', 'Mass / Hole', 'Start Time',
            'End Time', 'Pump Run Time (Hr)', 'Standby Time (Hr)', 'Loading Utilization (%)',
            'EP1 (bar)', 'EP2 (bar)', 'ET1 (C)', 'ET2 (C)', 'S1 Flow (L/min)', 'S1 Tank Lvl', 'Hyd Pres. (Bar)',
            'Hyd Temp. (C)', 'Hyd Flow (L/min)', 'Pump Life Mass (t)'
        ];

        const dateTimeFormatter = new Intl.DateTimeFormat('en-UK', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', second: '2-digit',
            hourCycle: 'h23'
        });

        const rows = data.reduce((acc, item) => {
            if (item['SourceType'] !== 'UG') {
                return acc;
            }
            const {
                pumpMassKG = 0, pumps3_actualFlow = 0, idleTimeS = 0, runtimeMS = 0, 'Blast Card': blastCard,
                Site: site, Name: name, Serial: serial, 'Date-Time': dateTime, emulPres1, emulPres2, emulTemp1,
                emulTemp2, sensLvl = 0, hydPres, hydTemp, hydFlow, lifeMassKG = 0
            } = item;

            if (pumpMassKG > 0) {
                const date = new Date(dateTime);
                const month = date.toLocaleString('default', {month: 'long'});
                const formattedDate = dateTimeFormatter.format(date);
                const endTime = new Date(date.getTime() + runtimeMS);

                acc.push({
                    'Site': site,
                    'Rig Name': name || serial,
                    'Month': month,
                    'Blast Card': blastCard,
                    'Holes': 1,
                    'Mass (kg)': pumpMassKG < 10000 ? pumpMassKG : 0,
                    'Mass / Hole': pumpMassKG < 10000 ? pumpMassKG : 0,
                    'Start Time': formattedDate,
                    'epoch': dateTime,
                    'End Time': dateTimeFormatter.format(endTime),
                    'Pump Run Time (Hr)': runtimeMS / 3600000,
                    'Standby Time (Hr)': (idleTimeS * 1000) / 3600000,
                    'Loading Utilization (%)': (100 * (runtimeMS / (runtimeMS + (idleTimeS * 1000)) || 1)).toFixed(2),
                    'EP1 (bar)': emulPres1 ? emulPres1[0] : 0,
                    'EP2 (bar)': emulPres2 ? emulPres2[0] : 0,
                    'ET1 (C)': emulTemp1 ? emulTemp1[0] : 0,
                    'ET2 (C)': emulTemp2 ? emulTemp2[0] : 0,
                    'S1 Flow (L/min)': pumps3_actualFlow < 10000 ? pumps3_actualFlow : 0,
                    'S1 Tank Lvl': sensLvl,
                    'Hyd Pres. (Bar)': hydPres ? hydPres[0] : 0,
                    'Hyd Temp. (C)': hydTemp ? hydTemp[0] : 0,
                    'Hyd Flow (L/min)': hydFlow ? hydFlow[0] : 0,
                    'Pump Life Mass (t)': lifeMassKG / 1000
                });
            }
            return acc;
        }, []);

        return [rows, cols];
    }


    const getInitialOverlays = (dict) => {
        let overlays = []
        let colors = horizontalPalette

        emulsionPerAsset.forEach((item, index) => {
            overlays.push({
                name: item['x'],
                data: dict[item['x']],
                color: colors[index % colors.length]
            })
        })

        return overlays
    }


    const getTableData = (data) => {

        let rows = []
        let cols = new Set()
        Object.keys(emulsionPerAssetPerPeriodDict).forEach(k => {
            let row = {}
            row['Rig Name'] = k
            emulsionPerAssetPerPeriodDict[k].forEach((item) => {
                row[item['x']] = item['y']
                cols.add(item['x'])
            })
            rows.push(row)

        })

        return [rows, ['Rig Name', ...Array.from(cols)]]
    }


    const formatDateRange = (start, end) => {
        const options = {day: 'numeric', month: 'short'};
        const startStr = new Intl.DateTimeFormat('en-UK', options).format(start);
        const endStr = new Intl.DateTimeFormat('en-UK', options).format(end);
        return `${startStr} - ${endStr}`;
    };


    function adjustDate(date, period) {
        date = date.hour(0).minute(0).second(0).millisecond(0);

        if (period === 'day' || period === 'month' || period === 'week') {
            date = date.hour(0);
        }
        if (period === 'month') {
            date = date.date(1);
        }

        return date;
    }

    const getEmulsionPerPeriod = (startDate, endDate, data, period) => {
        if (data.length === 0) return [];

        startDate = adjustDate(startDate, period);
        endDate = adjustDate(endDate, period);

        const allDates = generateDateRange(startDate, endDate, period);


        const final = allDates.reduce((acc, dateRange) => {
            acc[dateRange.label] = {x: dateRange.label, y: [0, 0, 0]};
            return acc;
        }, {});


        // Aggregate data
        data.forEach(item => {
            if (item['SourceType'] !== 'UG') return;
            const sensiMass = item['pumpMassKG'] < 500 ? item['pumpMassKG'] : 0;
            const waterMass = item['waterMassKG'] ?? 0;
            const emulMass = item['emulMassKG'] ?? 0;
            const date = item['Date-Time']

            const dateLabel = getDateLabel(date, allDates);


            if (final[dateLabel]) {
                final[dateLabel].y[0] += emulMass;
                final[dateLabel].y[1] += sensiMass;
                final[dateLabel].y[2] += waterMass;
            }
        });


        return Object.values(final).map(item => ({
            ...item,
            x: formatDateLabel(item.x, period)
        }));
    };


    const getEmulsionPerAssetPerPeriod = (data, period, asset) => {

        const filteredData = data.filter((item) => (item['SourceType'] === 'UG' && (item['Name'] || item['Serial'])) === asset);

        let {startDateString, endDateString} = data.reduce((acc, item) => {
            if (item['SourceType'] !== 'UG') return acc;
            if (item['Date-Time'] < acc.startDateString) acc.startDateString = item['Date-Time'];
            if (item['Date-Time'] > acc.endDateString) acc.endDateString = item['Date-Time'];
            return acc;
        }, {startDateString: data[0]['Date-Time'], endDateString: data[0]['Date-Time']});

        let startDate = dayjs(startDateString);
        let endDate = dayjs(endDateString);

        const res = getEmulsionPerPeriod(startDate, endDate, filteredData || [], period);
        return res.map((item) => ({...item, y: item.y.reduce((acc, val) => acc + val, 0)}));
    };


    const getSummaryData = (data) => {
        let summary = {};
        let serials = new Set();
        let totalRuntimeVar = 0;
        let runtimeVar = 0;

        data.forEach((item) => {
            const {totalHoles = 0, pumpMassKG = 0, totalFaces = 0, runtimeMS = 0, totalRuntime = 0} = item;

            serials.add(item['Serial']);
            runtimeVar += runtimeMS / (1000 * 60 * 60);
            totalRuntimeVar += runtimeMS / (1000 * 60 * 60);

            // Accumulate mass in tons
            summary['Total Mass Delivered (Tons)'] = (summary['Total Mass Delivered (Tons)'] || 0) + pumpMassKG / 1000;
        });

        let locationData = Array.from(uniqueLocations || [])
            .map(location => locations[location] ? {...locations[location], name: location} : undefined)
            .filter(item => item !== undefined);  // Remove undefined entries


        // Formatting and calculating final values
        summary['Total Mass Delivered (Tons)'] = formatValue(summary['Total Mass Delivered (Tons)']);
        summary['Total Holes'] = formatValue(data.length);
        summary['Total Pump Run Time (Hrs)'] = formatValue(totalRuntimeVar);
        summary['Efficiency of Operations'] = `64%`;
        summary["Number of Units in Operation"] = formatValue(serials.size);
        summary["Location"] = locationData

        return summary;
    }

    const emulsionPerAsset = useMemo(() => getEmulsionPerAsset(data), [data]);
    const [periodOverlays, setPeriodOverlays] = useState(getInitialOverlays([]));

    const emulsionPerAssetPerPeriodDict = useMemo(() => {
        let dict = {}
        emulsionPerAsset.forEach((item) => {
            dict[item['x']] = getEmulsionPerAssetPerPeriod(data, period, item['x'])
        })
        setPeriodOverlays(getInitialOverlays(dict))
        return dict
    }, [period, data])


    useEffect(() => {
        if (!data.length) {
            return
        }
        //if date range is greater than 2 days, remove hour option
        let [min, max] = getDateRange(data);
        let diff = (max - min) / (1000 * 60 * 60 * 24);
        let newPeriods = ['hour', 'day', 'week', 'month'];
        if (diff > 2) {
            newPeriods = ['day', 'week', 'month'];
        }

        let {startDateString, endDateString} = data.reduce((acc, item) => {
            if (item['SourceType'] !== 'UG') return acc;
            if (item['Date-Time'] < acc.startDateString) acc.startDateString = item['Date-Time'];
            if (item['Date-Time'] > acc.endDateString) acc.endDateString = item['Date-Time'];
            return acc;
        }, {startDateString: data[0]['Date-Time'], endDateString: data[0]['Date-Time']});

        let startDate = dayjs(startDateString);
        let endDate = dayjs(endDateString);

        setPeriods(newPeriods);

        newPeriods.forEach((value) => {
            allData.current[value] = getEmulsionPerPeriod(startDate, endDate, data, value);
        });

        // setPeriodOverlays(getInitialOverlays(emulsionPerAssetPerPeriodDict))

    }, [data, period]);


    const overlays = useRef([])
    const periodRef = useRef(period);


    const [periodTotalBarChart, setPeriodTotalBarChart] = useState(null);




    // const periodTotalBarChart = useMemo(() => <BarChart
    //     id={'period-total-bar-chart'}
    //     stacked={false}
    //     colorPalette={verticalPalette}
    //     xaxisLabel={'Date'}
    //     yaxisLabel={'Total Mass (kg)'}
    //     containerRef={containerRef}
    //     orientation={'vertical'}
    //     data={allData.current[period]}
    //     keysInput={['Emulsion Mass', 'Sensitiser Mass', 'Water Mass']}
    //     dimensions={{width: '100%', height: '80%'}}
    //     ytickFormat={d3.format(".2s")}
    //     overlayData={periodOverlays}
    // />, [periodOverlays])


    const [assetTotalBarChart, setAssetTotalBarChart] = useState(null);

    useEffect(() => {
        if (emulsionPerAsset.length > 0) {
            console.log('periodTotalBarChart', periodOverlays)
            setAssetTotalBarChart(
                <BarChart
                    id={'asset-total-bar-chart'}
                    stacked={false}
                    colorPalette={horizontalPalette}
                    onHtmlExtract={(html) => assetYAxisRef.current = html}
                    containerRef={containerRef2}
                    orientation={'horizontal'}
                    data={emulsionPerAsset}
                    onMouseOver={(itemSerial, color) => {
                        setPeriodOverlays(prevOverlays => {
                            const itemExists = prevOverlays.some(overlay => overlay.name === itemSerial);
                            if (itemExists) {
                                return prevOverlays.filter(overlay => overlay.name !== itemSerial);
                            } else {
                                // If it doesn't exist, add the new object
                                const newObj = {
                                    name: itemSerial,
                                    data: emulsionPerAssetPerPeriodDict[itemSerial],
                                    color: color
                                };
                                return [...prevOverlays, newObj];
                            }
                        });
                    }}
                    onMouseLeave={() => overlays.current = []}
                    keysInput={['Emulsion Mass', 'Sensitiser Mass', 'Water Mass']}
                    dimensions={{width: '100%', height: '80%'}}
                    xtickFormat={d3.format(".2s")}/>)
        }
    }, [data, period, periodOverlays, emulsionPerAssetPerPeriodDict]);

        useEffect(() => {
        if (allData.current[period]) {
            setPeriodTotalBarChart(
                <BarChart
                    id={'period-total-bar-chart'}
                    stacked={false}
                    colorPalette={verticalPalette}
                    xaxisLabel={'Date'}
                    yaxisLabel={'Total Mass (kg)'}
                    containerRef={containerRef}
                    orientation={'vertical'}
                    data={allData.current[period]}
                    keysInput={['Emulsion Mass', 'Sensitiser Mass', 'Water Mass']}
                    dimensions={{width: '100%', height: '80%'}}
                    ytickFormat={d3.format(".2s")}
                    overlayData={periodOverlays}
                />)
        }
    }, [ periodOverlays, emulsionPerAssetPerPeriodDict]);


    //
    // const assetTotalBarChart = useMemo(() => {
    //         return emulsionPerAsset.length > 0 && (
    //             <BarChart
    //                 id={'asset-total-bar-chart'}
    //                 stacked={false}
    //                 colorPalette={horizontalPalette}
    //                 onHtmlExtract={(html) => assetYAxisRef.current = html}
    //                 containerRef={containerRef2}
    //                 orientation={'horizontal'}
    //                 data={emulsionPerAsset}
    //                 onMouseOver={(itemSerial, color) => {
    //                     setPeriodOverlays(prevOverlays => {
    //                         const itemExists = prevOverlays.some(overlay => overlay.name === itemSerial);
    //                         if (itemExists) {
    //                             return prevOverlays.filter(overlay => overlay.name !== itemSerial);
    //                         } else {
    //                             // If it doesn't exist, add the new object
    //                             const newObj = {
    //                                 name: itemSerial,
    //                                 data: emulsionPerAssetPerPeriodDict[itemSerial],
    //                                 color: color
    //                             };
    //                             return [...prevOverlays, newObj];
    //                         }
    //                     });
    //                 }}
    //                 onMouseLeave={() => overlays.current = []}
    //                 keysInput={['Emulsion Mass', 'Sensitiser Mass', 'Water Mass']}
    //                 dimensions={{width: '100%', height: '80%'}}
    //                 xtickFormat={d3.format(".2s")}/>)
    //
    //     }, [emulsionPerAsset, period, periodOverlays]
    // )


    // const summaryData = useMemo(() => getSummaryData(data), [data]);
    const tableData = useMemo(() => getTableData(data), [emulsionPerAssetPerPeriodDict]);
    // const overviewData = useMemo(() => getOverviewData(data), [data]);
    const transferPumpCycleData = useMemo(() => getPlaceTransferPumpCycleData(data), [data]);


    useEffect(() => {
        console.log('rerendering content', periodOverlays)
        contentRef.current = (
            <OuterContainer>
                {(data.length) > 0 && (<InnerContainer>
                    {/*<SummarySection summaryData={summaryData}/>*/}

                    {emulsionPerAsset.length > 0 && (
                        <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
                            <BarChartSection
                                period={period}
                                periods={periods}
                                setPeriod={setPeriod}
                                isLoading={isLoading}
                                assetTotalBarChart={assetTotalBarChart}
                                currentPlot={periodTotalBarChart} // replace with your plot component
                                containerRef={containerRef}
                                containerRef2={containerRef2}
                                setPeriodOverlays={setPeriodOverlays}
                            />


                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                                width: '100%',
                                justifyContent: 'space-between',
                                fontSize: '0.85em'
                            }}>
                                <div style={{width: '96vw', marginTop: '4vh', height: '40vh'}}>
                                    <DataTableContainer data={tableData}/>
                                </div>

                            </div>
                        </div>)}

                    <div style={{marginBottom: '5vh'}}>
                        <div style={{width: '96vw', maxHeight: '50vh', fontSize: '0.8em', marginTop: '4vh'}}>
                            <DataTableContainer data={[data, null]}
                                                dataMap={{
                                                    excludes: item => item['SourceType'] !== 'UG',
                                                    mapItem: (item) => {
                                                        return {
                                                            'Site': item['Site'],
                                                            'Rig Name': item['Name'] || item['Serial'],
                                                            'Month': dayjs(item['Date-Time']).format('MMMM'),
                                                            'Blast Card': item['Blast Card'],
                                                            'Transaction ID': item['UID10'],
                                                            'Holes': 1,
                                                            'Mass (kg)': item['pumpMassKG'] < 10000 ? item['pumpMassKG'] : 0,
                                                            'Mass / Hole': item['pumpMassKG'] < 10000 ? item['pumpMassKG'] : 0,
                                                            'Start Time': dayjs(item['Date-Time']).format('DD/MM/YYYY, HH:mm:ss'),
                                                            'End Time': dayjs(item['Date-Time'] + item['runtimeMS']).format('DD/MM/YYYY, HH:mm:ss'),
                                                            'Pump Run Time (Hr)': parseFloat(item['runtimeMS']),
                                                            'Standby Time (Hr)': parseFloat(item['idleTimeS']),
                                                            'Loading Utilization (%)': (100 * (item['runtimeMS'] / (item['runtimeMS'] + (item['idleTimeS'] * 1000)) || 1)),
                                                            'EP1 (bar)': item['emulPres1'] ? item['emulPres1'][0] : 0,
                                                            'EP2 (bar)': item['emulPres2'] ? item['emulPres2'][0] : 0,
                                                            'ET1 (C)': item['emulTemp1'] ? item['emulTemp1'][0] : 0,
                                                            'ET2 (C)': item['emulTemp2'] ? item['emulTemp2'][0] : 0,
                                                            'S1 Flow (L/min)': item['pumps3_actualFlow'] < 10000 ? item['pumps3_actualFlow'] : 0,
                                                            'S1 Tank Lvl': item['sensLvl'],
                                                            'Hyd Pres. (Bar)': item['hydPres'] ? item['hydPres'][0] : 0,
                                                        }
                                                    }
                                                }}


                                                firstColWidth={'10vw'}
                                                initialSort={{key: 'epoch', direction: 'descending'}}
                                                dataModel={[{groupBy: 'Site', equality: item => item}, {
                                                    groupBy: 'Rig Name',
                                                    equality: item => item
                                                }, {groupBy: 'Month'}, {
                                                    groupBy: 'Blast Card',
                                                    equality: item => item
                                                }, {
                                                    groupBy: 'Transaction ID',
                                                    preventCollapse: true,
                                                    // collapsedFormat: (item) => item,
                                                    // equality: (item) => console.log(item),
                                                    style: {fontWeight: 'regular', zIndex: 100}

                                                }]}
                                                groupRules={{
                                                    'Holes': {rule: 'sum'},
                                                    'Mass (kg)': {
                                                        rule: 'sum',
                                                        groupFormat: (item) => item.toFixed(1),
                                                        individualFormat: (item) => item.toFixed(2)
                                                    },
                                                    'Mass / Hole': {
                                                        rule: 'mean',
                                                        groupFormat: (item) => item.toFixed(1)
                                                    },
                                                    'Pump Run Time (Hr)': {
                                                        rule: 'sum',
                                                        groupFormat: (item) => (item / (60 * 60 * 1000)).toFixed(2),
                                                        individualFormat: (item) => (item / (60 * 60 * 1000)).toFixed(3)
                                                    },
                                                    'Standby Time (Hr)': {
                                                        rule: 'sum',
                                                        groupFormat: (item) => (item / (60 * 60)).toFixed(2),
                                                        individualFormat: (item) => (item / (60 * 60)).toFixed(3)
                                                    },
                                                    'Loading Utilization (%)': {
                                                        rule: 'mean',
                                                        groupFormat: (item) => item.toFixed(0),
                                                        individualFormat: (item) => item.toFixed(1)
                                                    },
                                                    'Start Time': {rule: 'first', groupFormat: (item) => item},
                                                    'End Time': {rule: 'last',},
                                                    'EP1 (bar)': {rule: 'mean', groupFormat: (item) => item.toFixed(1)},
                                                    'EP2 (bar)': {rule: 'mean', groupFormat: (item) => item.toFixed(1)},
                                                    'ET1 (C)': {rule: 'mean', groupFormat: (item) => item.toFixed(1)},
                                                    'ET2 (C)': {rule: 'mean', groupFormat: (item) => item.toFixed(1)},
                                                    'S1 Flow (L/min)': {
                                                        rule: 'mean',
                                                        groupFormat: (item) => item.toFixed(1),
                                                        individualFormat: (item) => item.toFixed(1)
                                                    },
                                                    'Hyd Pres. (Bar)': {
                                                        rule: 'mean',
                                                        groupFormat: (item) => item.toFixed(1)
                                                    },
                                                    'Hyd Temp. (C)': {
                                                        rule: 'mean',
                                                        groupFormat: (item) => item.toFixed(2)
                                                    },
                                                    'Hyd Flow (L/min)': {
                                                        rule: 'mean',
                                                        groupFormat: (item) => item.toFixed(2)
                                                    },
                                                    'Pump Life Mass (t)': {
                                                        rule: 'sum',
                                                        groupFormat: (item) => item.toFixed(2)
                                                    },
                                                    'S1 Tank Lvl': {
                                                        rule: 'mean',
                                                        groupFormat: (item) => item.toFixed(0)
                                                    },
                                                }}
                                                title={'Loading Operations by Devex - Detailed'}
                                                collapseEqaulity={(item) => item}/>
                        </div>
                    </div>

                </InnerContainer>)}
            </OuterContainer>
        )
        setTrigger(trigger + 1)
    }, [renderContent, assetTotalBarChart, periodTotalBarChart]);

    return renderContent ? contentRef.current : null


};

export default InventoryOffloader;
