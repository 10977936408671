import React, {useEffect, useMemo, useRef, useState} from 'react';
import './PlotsScreen.css';
import DataTable from "../../Components/DataTable/DataTable";
import BarChart from "../../Components/BarChart/BarChart";
import * as d3 from "d3";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import GoogleMapReact from 'google-map-react';
import PlaceIcon from '@mui/icons-material/Place';
import locations from "../../locations";
import './BarChartSection.css'
import {BarChart as MBarChart} from '@mui/x-charts/BarChart';
import moment from "moment/moment";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);


const horizontalPalette = ['#E66C37', '#5BD667', '#12239E', '#D9B300', '#4092FF', '#E044A7', '#744EC2', '#15C6F4', '#1AAB40', '#F472D0', '#197278', '#FF8080', '#6B007B', '#D64550'];

const verticalPalette = ['rgba(188,194,197,0.45)']

function formatValue(value) {
    let formattedValue;
    if (value < 1) {
        formattedValue = value.toFixed(2);
    } else {
        formattedValue = d3.format('.4s')(value);
    }
    return formattedValue.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
}


const styles = {
    outerDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }, innerDiv: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', width: '96vw',
    }, columnFlexCenter: {
        display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'
    }, rowFlexSpaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '18vh',
        maxHeight: '18vh',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4vh',
        elevation: 10,
        marginTop: '4vh'

    }, itemDiv: {
        width: '15%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        borderRadius: 10,
        fontSize: '0.8vw',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        backgroundColor: 'white',
        fontFamily: "montserrat, sans-serif",
    }, labelStyle: {
        margin: 5, fontSize: '1em'
    }, chartContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '55%',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        borderRadius: 10,
        padding: 10,
        boxSizing: 'border-box'
    }, chartArea: {
        display: 'flex', flexDirection: 'row', height: 'calc(100% - 20px)', width: '100%'
    }, yAxisLabel: {
        // height: '100%',
        width: '2%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // transformOrigin: 'left bottom',

        // marginRight: 20,
        // marginLeft: 5

    }, yAxisText: {
        transform: 'rotate(-90deg) translate(-150%, -0%)  ',
        whiteSpace: 'nowrap',
        fontFamily: 'acumin-pro, sans-serif',
        // position: 'absolute',
        // top: '50%',
        // left:' 50%',
    }, chartDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 'calc(98% - 20px)',
        paddingLeft: 10,
        height: '98%',
        borderRadius: 10,
    }, dataTableContainer: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        height: '52vh',
        maxHeight: '52vh',
        marginBottom: '8vh',
        marginTop: '4vh',

    }
};


const OuterContainer = ({children}) => {
    return <div style={styles.outerDiv}>{children}</div>;
};

const InnerContainer = ({children}) => {
    return <div style={styles.innerDiv}>{children}</div>;
};

const Marker = ({lat, lng, name}) => (
    <div style={{
        position: 'absolute',
        transform: 'translate(-50%, -100%)',  // Centers the marker
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <div style={{
            color: 'black',
            fontSize: '12px',  // Using fixed pixel size for text
            backgroundColor: 'white',
            borderRadius: 5,
            padding: 5,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        }}>
            {name}
        </div>
        <PlaceIcon style={{
            color: 'red',
            fontSize: '24px',  // Using fixed pixel size for icon
        }}/>
    </div>
);

const SummarySection = ({summaryData}) => {
    return (<div style={styles.rowFlexSpaceBetween}>
        {Object.entries(summaryData).map(([key, value]) => {
            if (key !== 'Location') return (
                <div style={styles.itemDiv}>
                    <div>{key}</div>
                    <div style={{
                        fontSize: '2vw', fontFamily: '', paddingTop: 10
                    }}>{value}</div>
                </div>
            )
            else {
                return (
                    <div style={{...styles.itemDiv}}>
                        <div style={{height: '100%', width: '100%'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: 'AIzaSyAMbGyE-dp9LIvL4scaCw3jV63J5OQ4MT0'}}
                                defaultCenter={{
                                    lat: summaryData['Location'][0]?.lat ?? 0,
                                    lng: summaryData['Location'][0]?.lon ?? 0
                                }}
                                defaultZoom={6}
                                options={{
                                    zoomControl: false,
                                    mapTypeControl: false,
                                    streetViewControl: false,
                                    keyboardShortcuts: false,
                                }}
                            >
                                {summaryData['Location'].map(site => (
                                    <Marker
                                        key={site.name + 'offloader'} // Assuming each site has a unique name for key prop
                                        lat={site.lat}
                                        lng={site.lon}
                                        name={site.name}
                                    />
                                ))}
                            </GoogleMapReact>
                        </div>
                    </div>
                )
            }
        })}
    </div>);
};

const getPlaceTransferPumpCycleData = (pumpData) => {
    let cols = ['Date-Time', 'UID', 'Receiver', 'RIG Name', 'Mass (Kg)'];
    let rows = pumpData.map((item) => {
        // if (item['unitLoaded'] === 'None') return null;
        return {
            'Date-Time': new Date(item['Date-Time']).toLocaleString(),
            'UID': item['UID'],
            'RIG Name': item['Name'] || item['Serial'],
            'Receiver': item['unitLoaded'] === 'None' ? 'Default' : item['unitLoaded'],
            'Mass (Kg)': item['emulMassKG']
        }
    });


    return [rows, cols]
}

const DataTableContainer = ({data, title, collapseOn, dataModel, initialSort}) => {
    return (<div style={styles.dataTableContainer}>
        {title && <div className='table-header-display'>{title}</div>}

        <div style={{
            height: title ? '90%' : '100%',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative'
        }}>
            <DataTable data={data[0]}
                       initialSort={initialSort}
                       columns={data[1]}
                       collapseOn={collapseOn}
                       dataModel={dataModel}
            />
        </div>
    </div>);
};

const Label = ({children, value, onChange, period}) => {
    return (<label style={styles.labelStyle}>
        <input type="radio" value={value} checked={period === value} onChange={onChange}/>
        {children}
    </label>);
};

const PrimaryChart = ({style, title, xLabel, yLabel, isLoading, currentPlot, containerRef}) => {
    return (
        <div key={title} style={style}>
            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                {title}
            </div>
            <div style={styles.chartArea}>
                <div style={styles.yAxisLabel}>
                    <span style={styles.yAxisText}>{yLabel}</span>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}
                     ref={containerRef}>
                    <div style={{
                        ...styles.chartDiv,
                        display: 'flex',
                        // filter: isLoading ? 'blur(2px)' : 'none',
                        // backgroundColor: isLoading ? 'red' : 'transparent',
                        height: 'calc(85% - 20px)',
                        // overflow: 'auto'

                    }}>
                        {currentPlot}
                    </div>

                    <div style={{
                        height: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        justifySelf: 'center',
                        alignSelf: 'center'
                    }}>
                        <span style={{
                            fontFamily: 'acumin-pro, sans-serif',

                        }}>{xLabel}</span>
                    </div>
                </div>
            </div>
        </div>);
};

const BarChartSection = ({
                             isLoading,
                             currentPlot,
                             assetTotalBarChart,
                             containerRef,
                             containerRef2,
                             period,
                             periods,
                             setPeriod,
                             setPeriodOverlays,
                         }) => {


    return (
        <div className="bar-chart-section">
            <div className="left-chart-container">
                <div className="period-selector">
                    {periods.map((value) => (
                        <Label period={period} key={value} value={value} onChange={() => setPeriod(value)}>
                            {value.charAt(0).toUpperCase() + value.slice(1)}
                        </Label>
                    ))}
                </div>

                <PrimaryChart
                    xLabel={'Date'}
                    yLabel={'Mass (kg)'}
                    title={
                        <div className="chart-title">
                            <div className="chart-title-text">Transfer Mass Total by Date</div>
                            <div className="chart-title-icon">
                                <ClearAllIcon style={{cursor: 'pointer'}} onClick={() => setPeriodOverlays([])}/>
                            </div>
                        </div>
                    }
                    style={{flexGrow: 1, maxHeight: '100%'}}
                    isLoading={isLoading}
                    currentPlot={currentPlot}
                    containerRef={containerRef}
                />
            </div>

            <div className='right-chart-container'>
                <PrimaryChart
                    xLabel={'Mass (kg)'}
                    yLabel={'Asset'}
                    title={
                        <div className="asset-title">
                            <div className="asset-title-text">Asset Total (kg)</div>
                            <div id="mass-title" className="mass-title"/>
                        </div>
                    }
                    style={{width: '36vw', maxHeight: '100%'}}
                    isLoading={isLoading}
                    currentPlot={assetTotalBarChart}
                    containerRef={containerRef2}
                />
            </div>
        </div>
    );
};

const pad = (num) => num.toString().padStart(2, '0');

const precomputeDateRanges = (dateRanges) => {
    return dateRanges.map(range => {
        let [start, end] = range.split(' - ').map(d => dayjs(d));
        start = start.startOf('day');
        if (end) {
            end = end.endOf('day');
        }
        return {start, end, range};
    });
};


const generateDateRange = (startDate, endDate, period) => {
    let dates = [];
    let currentDate = dayjs(startDate);

    const formatDateRange = (start, end) => {
        const startStr = dayjs(start).format('YYYY/MM/DD');
        const endStr = dayjs(end).format('YYYY/MM/DD');
        return `${startStr} - ${endStr}`;
    };

    while (currentDate.isSameOrBefore(endDate)) {
        switch (period) {
            case 'hour':
                dates.push(formatDate(currentDate.valueOf(), period));
                currentDate = currentDate.add(1, 'hour');
                break;
            case 'day':
                dates.push(formatDate(currentDate.valueOf(), period));
                currentDate = currentDate.add(1, 'day');
                break;
            case 'week':
                let weekStartDate = currentDate.clone();
                let weekEndDate = currentDate.clone().add(6, 'day');

                if (weekEndDate.isAfter(endDate)) {
                    weekEndDate = dayjs(endDate);
                }

                dates.push(formatDateRange(weekStartDate, weekEndDate));
                currentDate = currentDate.add(7, 'day');
                break;
            case 'month':
                dates.push(formatDate(currentDate.valueOf(), period));
                currentDate = currentDate.add(1, 'month');
                break;
            default:
                throw new Error('Invalid period specified');
        }
    }

    return dates;
};

const getDateLabel = (timestamp, dateRanges) => {
    for (const {start, end, label} of dateRanges) {
        if (timestamp >= start && timestamp <= end) {
            return label;
        }
    }
};

const formatDateLabel = (timestamp, period) => {
    let date
    //if timestamp is a string
    if (typeof timestamp !== 'string') {
        date = dayjs(timestamp);
    }
    switch (period) {
        case 'hour':
            return date.format('HH DD/MM/YYYY');
        case 'day':
            return date.format('DD/MM/YYYY');
        case 'week':
            let [start, end] = timestamp.split(' - ').map(e => parseInt(e))
            return `${dayjs(start).format('DD/MM/YYYY')} - ${dayjs(end).format('DD/MM/YYYY')}`;
        case 'month':
            return date.format('MM/YYYY');
        default:
            return date.format('DD-MM-YYYY HH:mm');
    }
};

const adjustDate = (date, period) => {
    const d = dayjs(date);
    switch (period) {
        case 'hour':
            return d.startOf('hour').valueOf();
        case 'day':
            return d.startOf('day').valueOf();
        case 'week':
            return d.startOf('week').valueOf();
        case 'month':
            return d.startOf('month').valueOf();
        default:
            return d.valueOf();
    }
};

const formatDate = (timestamp, period, dateRanges = []) => {
    const date = dayjs(timestamp);
    let precomputedRanges = precomputeDateRanges(dateRanges);

    switch (period) {
        case 'hour':
            return date.format('YYYY-MM-DD HH');
        case 'day':
            return date.format('YYYY/MM/DD');
        case 'week':
            for (const {start, end, range} of precomputedRanges) {
                if (date.isBetween(start, end, null, '[]')) {
                    return `${start.format('YYYY/MM/DD')} - ${end.format('YYYY/MM/DD')}`;
                }
            }
            console.log('Date not in range', precomputedRanges, date.format('YYYY/MM/DD HH mm'))
            return null;
        case 'month':
            return date.format('YYYY-MM');
        default:
            return date.format('DD-MM-YYYY HH:mm');
    }
};


const getEmulsionPerAsset = (data) => {
    let final = data.reduce((acc, item) => {
        if (item['SourceType'] !== 'Offloader') {
            return acc;
        }
        let emulMass = item['unitMassKG']
        let serial = item['Name'] || item['Serial'];
        if (!acc[serial]) {
            acc[serial] = {
                x: serial, y: [emulMass],
            };
        } else {
            acc[serial].y[0] += emulMass !== 'null' ? emulMass : 0;
        }
        return acc;
    }, {});

    //remove items with 0 mass
    final = Object.fromEntries(Object.entries(final).filter(([key, value]) => value.y[0] > 0));

    //if there are no items with mass, return empty array
    if (Object.keys(final).length === 0) {
        return []
    }

    final = Object.fromEntries(Object.entries(final).sort(([, a], [, b]) => b.y.reduce((acc, v) => acc + v, 0) - a.y.reduce((acc, v) => acc + v, 0)));
    return Object.values(final)
}


function PlotsScreen2({screen, data, pumpData}) {
    const [period, setPeriod] = useState('day');
    const containerRef = useRef(null);
    const containerRef2 = useRef(null);
    const [periods, setPeriods] = useState(['hour', 'day', 'month']);
    const allData = useRef({});
    let uniqueLocations = useMemo(() => new Set(data.map(item => item['Site'])), [data]);
    const [isLoading, setIsLoading] = useState(false); // New state for loading
    const assetYAxisRef = useRef(null);
    const [trigger, setTrigger] = useState(false);
    const contentRef = useRef(null);
    const [renderContent, setRenderContent] = useState(false);


    useEffect(() => {
        if (!contentRef.current && screen) {
            setRenderContent(true);
        }
        if (!screen) {
            setRenderContent(false);
        }
    }, [screen]);

    useEffect(() => {
        setRenderContent(screen);
    }, [screen]);

    useEffect(() => {
        setTrigger(!trigger)
    }, [containerRef.current?.clientWidth, containerRef.current?.clientHeight, containerRef2.current?.clientWidth, containerRef2.current?.clientHeight]);


    const getInitialOverlays = () => {
        let overlays = []
        let colors = horizontalPalette

        let serials = new Set()
        pumpData.forEach((item) => {
            if (item['SourceType'] === 'Offloader') {
                serials.add(item['Name'] || item['Serial'])
            }
        })

        let emulsionPerAsset = getEmulsionPerAsset(pumpData)

        emulsionPerAsset.forEach((item, index) => {
            overlays.push({
                name: item['x'],
                data: emulsionPerAssetPerPeriodDict[item['x']] || [],
                color: colors[index % colors.length]
            })
        })
        return overlays
    }


    const getTableData = (data) => {


        let rows = []
        let cols = new Set()

        Object.keys(emulsionPerAssetPerPeriodDict).forEach(k => {
            let row = {}
            row['Receiver'] = k
            emulsionPerAssetPerPeriodDict[k].forEach((item) => {
                row[item['x']] = item['y'] > 0 ? item['y'] : null
                cols.add(item['x'])
            })
            rows.push(row)

        })


        return [rows, ['Receiver', ...Array.from(cols)]]
    }


    // const generateDateRange = (startDate, endDate, period) => {
    //     let dates = [];
    //     let currentDate = new Date(startDate);
    //
    //
    //     while (currentDate <= endDate) {
    //         switch (period) {
    //             case 'hour':
    //                 dates.push(formatDate(currentDate.getTime(), period));
    //                 currentDate.setHours(currentDate.getHours() + 1);
    //                 break;
    //             case 'day':
    //                 dates.push(formatDate(currentDate.getTime(), period));
    //                 currentDate.setDate(currentDate.getDate() + 1);
    //                 break;
    //             case 'week':
    //                 let weekStartDate = new Date(currentDate);
    //                 let weekEndDate = new Date(currentDate);
    //                 weekEndDate.setDate(weekStartDate.getDate() + 6);
    //                 if (weekEndDate > endDate) {
    //                     weekEndDate = endDate;
    //                 }
    //
    //                 dates.push(formatDateRange(weekStartDate, weekEndDate));
    //                 currentDate.setDate(currentDate.getDate() + 7);
    //                 break;
    //             case 'month':
    //                 dates.push(formatDate(currentDate.getTime(), period));
    //                 currentDate.setMonth(currentDate.getMonth() + 1);
    //                 break;
    //             default:
    //                 throw new Error('Invalid period specified');
    //         }
    //     }
    //     return dates;
    // };


    const adjustDate = (date, period) => {
        const d = dayjs(date);
        switch (period) {
            case 'hour':
                return d.startOf('hour').valueOf();
            case 'day':
                return d.startOf('day').valueOf();
            case 'week':
                return d.startOf('week').valueOf();
            case 'month':
                return d.startOf('month').valueOf();
            default:
                return d.valueOf();
        }
    };


    const getEmulsionPerPeriod = (startDate, endDate, data, period) => {
        if (pumpData.length === 0) return [];


        const allDates = generateDateRange(startDate, endDate, period);

// Initialize the accumulator with all dates
        const initialAcc = allDates.reduce((acc, dateLabel) => {
            acc[dateLabel] = {x: dateLabel, y: [0, 0, 0]};
            return acc;
        }, {});

// Process the data to accumulate the results
        const final = data.reduce((acc, item) => {
            if (item['SourceType'] === 'Offloader') {
                const emulMass = item['unitMassKG'] !== 'null' ? parseFloat(item['unitMassKG']) : 0;
                const dateLabel = formatDate(item['Date-Time'], period, allDates);

                if (!acc[dateLabel]) {
                    acc[dateLabel] = {x: dateLabel, y: [0, 0, 0]};
                }

                acc[dateLabel].y[0] += emulMass;
            }
            return acc;
        }, initialAcc);


        return Object.values(final).sort()
        // return Object.values(final).sort((a, b) => a.x.localeCompare(b.x));
    };

    const getEmulsionPerAssetPerPeriod = (data, period, asset) => {

        const filteredData = data.filter((item) => (item['Name'] || item['Serial']) === asset);

        let {startDateString, endDateString} = data.reduce((acc, item) => {
            if (item['Date-Time'] < acc.startDateString) acc.startDateString = item['Date-Time'];
            if (item['Date-Time'] > acc.endDateString) acc.endDateString = item['Date-Time'];
            return acc;
        }, {startDateString: data[0]['Date-Time'], endDateString: data[0]['Date-Time']});


        console.log('start', startDateString, 'end', endDateString)
        let startDate = dayjs(startDateString)
        let endDate = dayjs(endDateString)

        const res = getEmulsionPerPeriod(startDate, endDate, filteredData || [], period);


        return res.map((item) => ({...item, y: item.y.reduce((acc, val) => acc + val, 0)}));
    };


    const getSummaryData = (data) => {
        let summary = {};
        let serials = new Set();
        let totalRuntimeVar = 0;
        let runtimeVar = 0;

        data.forEach((item) => {
            const {
                Serial,
                totalHoles = 0,
                pumpMassKG = 0,
                totalFaces = 0,
                runtimeMS = 0,
                totalRuntime = 0
            } = item;

            serials.add(Serial);
            runtimeVar += runtimeMS / (1000 * 60 * 60);
            totalRuntimeVar += runtimeMS / (1000 * 60 * 60);

            // Accumulate mass in tons
            summary['Total Mass Delivered (Tons)'] = (summary['Total Mass Delivered (Tons)'] || 0) + pumpMassKG / 1000;
        });

        let locationData = Array.from(uniqueLocations || [])
            .map(location => locations[location] ? {...locations[location], name: location} : undefined)
            .filter(item => item !== undefined);  // Remove undefined entries


        // Formatting and calculating final values
        summary['Total Mass Delivered (Tons)'] = formatValue(summary['Total Mass Delivered (Tons)']);
        summary['Total Holes'] = formatValue(data.length);
        summary['Total Pump Run Time (Hrs)'] = formatValue(totalRuntimeVar);
        summary['Efficiency of Operations'] = `64%`;
        summary["Number of Units in Operation"] = formatValue(serials.size);
        summary["Location"] = locationData

        return summary;
    }

    const getDateRange = (data) => {
        let min = dayjs().valueOf();
        let max = 0;
        data.forEach((item) => {
            let date = dayjs(item['Date-Time']).valueOf();
            if (date < min) {
                min = date;
            }
            if (date > max) {
                max = date;
            }
        });

        return [min, max];
    };


    useEffect(() => {
        if (!pumpData.length) {
            return
        }
        //if date range is greater than 2 days, remove hour option
        let [min, max] = getDateRange(pumpData);
        let diff = (max - min) / (1000 * 60 * 60 * 24);
        let newPeriods = ['hour', 'day', 'week', 'month'];
        if (diff > 2) {
            newPeriods = ['day', 'week', 'month'];
        }

        let {startDateString, endDateString} = pumpData.reduce((acc, item) => {
            if (item['Date-Time'] < acc.startDateString) acc.startDateString = item['Date-Time'];
            if (item['Date-Time'] > acc.endDateString) acc.endDateString = item['Date-Time'];
            return acc;
        }, {startDateString: pumpData[0]['Date-Time'], endDateString: pumpData[0]['Date-Time']});

        let startDate = new Date(startDateString);
        let endDate = new Date(endDateString);

        setPeriods(newPeriods);

        newPeriods.forEach((value) => {
            allData.current[value] = getEmulsionPerPeriod(startDate, endDate, pumpData, value);
        });


        setPeriodOverlays(getInitialOverlays())

    }, [pumpData]);


    const emulsionPerAsset = useMemo(() => getEmulsionPerAsset(pumpData), [pumpData]);

    const emulsionPerAssetPerPeriodDict = useMemo(() => {
        let dict = {}
        emulsionPerAsset.forEach((item) => {
            dict[item['x']] = getEmulsionPerAssetPerPeriod(pumpData, period, item['x'])
        })
        return dict
    }, [pumpData, period])


    const [periodOverlays, setPeriodOverlays] = useState(getInitialOverlays());
    const overlays = useRef([])
    const periodRef = useRef(period);
    const dataRef = useRef(data);




    const periodTotalBarChart = useMemo(() => <BarChart
        id={'period-total-bar-chart'}
        stacked={false}
        colorPalette={verticalPalette}
        xaxisLabel={'Date'}
        yaxisLabel={'Total Mass (kg)'}
        containerRef={containerRef}
        orientation={'vertical'}
        data={allData.current[period]}
        keysInput={['Emulsion Mass', 'Sensitiser Mass', 'Water Mass']}
        dimensions={{width: '100%', height: '80%'}}
        ytickFormat={d3.format(".2s")}
        overlayData={periodOverlays}
    />, [emulsionPerAssetPerPeriodDict, containerRef.current?.clientWidth, containerRef.current?.clientHeight, periodOverlays])

    const getAxisData = (items, doStack, orientation) => {
        let xaxis = [{scaleType: orientation === 'vertical' ? 'band' : null, data: items.map((item) => item.x)}]
        let series = []

        if (orientation === 'vertical') {
            for (let stack of items) {
                for (let i = 0; i < stack.y.length; i++) {
                    if (series[i] === undefined) {
                        series[i] = {
                            data: [],
                            stack: doStack ? 'stack' : i,
                            scaleType: orientation === 'horizontal' ? 'band' : null
                        }
                    }
                    series[i].data.push(!isNaN(stack.y[i]) ? stack.y[i] : 0)
                }
            }
        } else {
            for (let stack of items) {
                series.push({
                    data: [stack.y.reduce((acc, val) => {
                        acc += val;
                        return acc;
                    }, 0)],
                    scaleType: 'band'
                });
            }
        }

        if (orientation === 'horizontal') {
            xaxis.forEach((axis) => {
                delete axis.scaleType
            })
        } else {
            series.forEach((s) => {
                delete s.scaleType
            })
        }

        return {xaxis, series}
    }


    // const periodTotalBarChart = (
    //     <MBarChart
    //         series={(allData.current?.[period] ?? []).map((item) => {
    //             return {data: item.y}
    //         })}
    //         xAxis={{data: (allData.current?.[period] ?? []).map((item) => item.x)}}
    //         width="100%"
    //         height="80%"
    //     />
    // );
    //
    // const periodTotalBarChart = useMemo(() => {
    //     const {xaxis, series} = getAxisData(allData.current?.[period] ?? [], true, 'vertical')
    //     console.log('bardata', xaxis, series)
    //     return (
    //         <MBarChart
    //             series={series}
    //             xAxis={xaxis}
    //             width={containerRef.current?.clientWidth * .9 ?? '100%'}
    //             height={containerRef.current?.clientHeight ?? '80%'}
    //         />
    //     )
    // }, [period, allData.current[period], containerRef.current?.clientWidth, containerRef.current?.clientHeight]);


    // const assetTotalBarChart = useMemo(() => {
    //     const {xaxis, series} = getAxisData(emulsionPerAsset, false, 'horizontal')
    //     console.log('horizdata', xaxis, series)
    //     return (
    //         <MBarChart
    //             series={xaxis}
    //             xAxis={series}
    //             width={containerRef2.current?.clientWidth * .9 ?? '100%'}
    //             height={containerRef2.current?.clientHeight ?? '80%'}
    //             layout="horizontal"
    //         />
    //     )
    // }, [emulsionPerAsset, containerRef2.current?.clientWidth, containerRef2.current?.clientHeight]);

    const assetTotalBarChart = useMemo(() => {
            return (
                <BarChart
                    id={'asset-total-bar-chart'}
                    stacked={false}
                    colorPalette={horizontalPalette}
                    onHtmlExtract={(html) => assetYAxisRef.current = html}
                    containerRef={containerRef2}
                    orientation={'horizontal'}
                    data={emulsionPerAsset}
                    onMouseOver={(itemSerial, color) => {
                        setPeriodOverlays(prevOverlays => {
                            const itemExists = prevOverlays.some(overlay => overlay.name === itemSerial);
                            if (itemExists) {
                                return prevOverlays.filter(overlay => overlay.name !== itemSerial);
                            } else {
                                // If it doesn't exist, add the new object
                                const newObj = {
                                    name: itemSerial,
                                    data: emulsionPerAssetPerPeriodDict[itemSerial],
                                    color: color
                                };
                                return [...prevOverlays, newObj];
                            }
                        });
                    }}
                    onMouseLeave={() => overlays.current = []}
                    keysInput={['Emulsion Mass']}
                    dimensions={{width: '100%', height: '80%'}}
                    xtickFormat={d3.format(".2s")}/>)

        }, [emulsionPerAsset, period, periodOverlays]
    )


    useEffect(() => {
        periodRef.current = period;
        setPeriodOverlays(getInitialOverlays())
    }, [period]);


    const tableData = useMemo(() => getTableData(data), [emulsionPerAssetPerPeriodDict, period]);
    const pumpCycles = useMemo(() => getPlaceTransferPumpCycleData(pumpData), [pumpData]);

    useEffect(() => {
        contentRef.current = (
            <OuterContainer>
                {data.length > 0 && (<InnerContainer>
                    {/*<SummarySection summaryData={summaryData}/>*/}


                    <BarChartSection
                        period={period}
                        periods={periods}
                        setPeriod={setPeriod}
                        isLoading={isLoading}
                        assetTotalBarChart={assetTotalBarChart}
                        currentPlot={periodTotalBarChart}
                        containerRef={containerRef}
                        containerRef2={containerRef2}
                        setPeriodOverlays={setPeriodOverlays}
                    />


                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'space-between',
                        fontSize: '0.85em'
                    }}>
                        <div style={{width: '65%'}}>
                            <DataTableContainer data={tableData}/>
                        </div>
                        <div style={{width: '33%'}}>
                            <DataTableContainer title={'Transfer Pump - Individual Pump Cycles'}
                                                data={pumpCycles}
                                                initialSort={{key: 'Date-Time', direction: 'descending'}}
                                                dataModel={[{
                                                    groupBy: 'Date-Time',
                                                    equality: item => item.split(',')[0],
                                                    collapsedFormat: (item) => item.split(',')[1],

                                                }]}
                            />
                        </div>

                    </div>
                </InnerContainer>)}
            </OuterContainer>
        )
        setTrigger(!trigger)
    }, [renderContent, period, periodOverlays, assetTotalBarChart, periodTotalBarChart]);

    return renderContent ? contentRef.current : null
};

export default PlotsScreen2;
