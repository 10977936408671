import React, {useEffect, useState} from 'react';
import DatabaseContext from './DatabaseContext';
import {openIndexedDB, saveTransactionsToIndexedDB,} from './indexDBUtils';
import {getAllData, getProgress, getTotalProgress} from "./InitialFetch.mjs";
import {useAuth} from "./AuthContext";

const currentDate = new Date();
const firstDayOfPreviousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1,
    0, 0, 0, 0
);


const CURRENT_TIMESTAMP = Math.floor(new Date().getTime() / 1000);
const TWO_MONTHS_AGO = Math.floor(firstDayOfPreviousMonth.getTime() / 1000);
const TWO_WEEKS_IN_SECONDS = 2 * 7 * 24 * 60 * 60;
const TWO_WEEKS_AGO_TIMESTAMP = CURRENT_TIMESTAMP - TWO_WEEKS_IN_SECONDS;

console.log("Current Timestamp:", CURRENT_TIMESTAMP);
console.log("Two Weeks Ago Timestamp:", TWO_WEEKS_AGO_TIMESTAMP);

const MIN_DATE_MS = 1672524000; //1 January 2023
const currentDay = new Date();
currentDay.setHours(0, 0, 0, 0);
const CURRENT_DAY_TIMESTAMP = Math.floor(currentDay.getTime() / 1000);


const useIntervalEffect = (callback) => {
    useEffect(() => {
        // Set up the interval
        const intervalId = setInterval(() => {
            callback();
        }, 10000); // 60000 milliseconds = 1 minute

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [callback]); // Dependency array includes the callback to reset interval if callback changes
};


const DatabaseProvider = ({children}) => {
    // const {authToken} = useAuth();
    const authToken = {idToken: ''}
    const [transactionsDB, setTransactionsDB] = useState(null);
    const [numItemsLastTwoHrs, setnumItemsLastTwoHrs] = useState(null);
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const refresh = async () => {

        if (!shouldRefresh || !authToken.idToken) {
            return
        }
        try {
            return
            let db = await openIndexedDB({'transactions': 'month', 'timeSegments': 'const'});
            const {
                itemsByMonth,
                fetchedSegments
            } = await getAllData(authToken.idToken, 'https://ssknzqim70.execute-api.eu-west-1.amazonaws.com/test/data', CURRENT_DAY_TIMESTAMP, Math.floor(new Date().getTime() / 1000), db)

            if (Object.values(itemsByMonth)[0]?.length > numItemsLastTwoHrs) {
                setnumItemsLastTwoHrs(Object.values(itemsByMonth)[0].length)
                await saveTransactionsToIndexedDB(db, itemsByMonth)
                setTransactionsDB(db)

            }

        } catch (error) {
            console.error("Error refreshing data:", error)
        }
    }

    useIntervalEffect(refresh);


    useEffect(() => {
        const initDB = async () => {
            try {
                let authToken = {idToken: 'dummy'}
                if (!authToken.idToken) return; // Only proceed if authToken is available

                let db = await openIndexedDB({'transactions': 'month', 'timeSegments': 'const'});

                let {itemsByMonth, timeSegments} = await getAllData(
                    authToken.idToken,
                    'https://ssknzqim70.execute-api.eu-west-1.amazonaws.com/test/data',
                    TWO_WEEKS_AGO_TIMESTAMP,
                    // 1701635651,
                    Math.ceil(new Date().getTime() / 1000),
                    db,
                );
                console.log('Saving', itemsByMonth)
                await saveTransactionsToIndexedDB(db, itemsByMonth)
                setTransactionsDB(db);


                let obj = await getAllData(authToken.idToken, 'https://ssknzqim70.execute-api.eu-west-1.amazonaws.com/test/data',
                    CURRENT_DAY_TIMESTAMP, CURRENT_TIMESTAMP,
                    db)

                setnumItemsLastTwoHrs((Object.values(obj.itemsByMonth)[0] || []).length)

                return db

            } catch (error) {
                console.error("Error initializing databases:", error);
                // Handle the error appropriately
            }
        };



        initDB().then((db) => {
            let authToken = {idToken: 'dummy'}
            let {itemsByMonth, timeSegments} = getAllData(
                authToken.idToken,
                'https://ssknzqim70.execute-api.eu-west-1.amazonaws.com/test/data',
                MIN_DATE_MS,
                TWO_WEEKS_AGO_TIMESTAMP,
                db,
            ).then(() => setShouldRefresh(true))
        });


    }, []);


    return (
        <DatabaseContext.Provider value={{db: transactionsDB, getProgress, getTotalProgress}}>
            {children}
        </DatabaseContext.Provider>
    );
};

export default DatabaseProvider;
