// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

// In AuthContext or a separate auth utility
export const setToken = (token) => {
    sessionStorage.setItem('authToken', JSON.stringify(token));
};

export const getToken = () => {
    const tokenString = sessionStorage.getItem('authToken');
    return tokenString ? JSON.parse(tokenString) : null;
};

export const getUser = () => {
    const userString = sessionStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
};


export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(getToken());
    const [userInfo, setUserInfo] = useState(getUser());

    const setToken = (data) => {
        console.log("Setting token:", data);
        setAuthToken(data);
        sessionStorage.setItem('authToken', JSON.stringify(data)); // Persist token across reloads
    };

    const setUser = (data) => {
        console.log("Setting user:", data);
        setUserInfo(data);
        sessionStorage.setItem('user', JSON.stringify(data)); // Persist user across reloads
    };

    return (
        <AuthContext.Provider value={{ authToken, setToken, userInfo, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
