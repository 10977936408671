import React from 'react';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import DatabaseProvider from './DatabaseProvider';
import {AuthProvider, useAuth} from './AuthContext';
import {LoginPageFirst} from "./screens/LoginPageFirst";
import {DashboardFirst} from "./screens/DashboardFirst";
import CallbackPage from "./screens/CallbackPage";
import RedirectPage from "./screens/RedirectPage";
import SignUpForm from "./screens/Users/SignUpForm";
import UsersPage from "./screens/Users/Users";
import UsersAndRolesContainer from "./Components/UsersAndRolesContainer";
import LoginPage from "./screens/Login/Login";
import CompanyRedirect from "./screens/CompanyRedirect/CompanyRedirect";


const router = createBrowserRouter([
    {
        path: "/login",
        element: <LoginPage/>,
    },
    {
        path: "/*",
        element: <LoginPage/>,
    },

    {path: "/company-redirect", element: <CompanyRedirect companies={['test', 'enaex']}/>},

]);

const App = () => {
    return (
        <AuthProvider>
            <RouterProvider router={router}/>
        </AuthProvider>
    );
};

export default App;
